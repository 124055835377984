import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie-player'
import GirlMeasuring from './data/measuring-tape-girlv4.json'
import Clock from './data/FIES-new.json'
import Plants from './data/plants.json'
import styled from 'styled-components'
import Burger from './components/Burger'
import { ReactComponent as Logo} from './assets/img/logoNoText.svg';

const StartDiv = styled.div`
    display: ${x => x.visible ? "grid" : "none"};
    grid-template-rows: 15vh 50vh 35vh;
    padding-bottom: 6px;
    background-color: #F0F0F0;
    z-index: -1;
    @media (max-width: 768px) {
        grid-template-rows: 10vh 80vh 10vh;
    }
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr 150px;
    grid-template-rows: 2fr 1fr;
    @media (max-width: 768px) {
        grid-template-columns: 75px 1fr 50px;
        grid-template-rows: 1fr 1fr;

    }
`

const MenuDiv = styled.div`
    grid-area: 1 / 3 / 3 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
`

const HungerClock = styled.p`
    font-family: Work Sans;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    grid-area: 1 / 2 / 2 / 3;
    align-self: end;
    justify-self: start;
    margin: 10px 0 0 0;
    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-height: 440 px) {
        font-size: 16px;
    }
    @media (max-width: 320px) {
        font-size: 13px;
    }
`
const ByWDL = styled(HungerClock)`
    font-size: 18px;
    grid-area: 2 / 2 / 3 / 3;
    align-self: start;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 2px;
    margin: 0 0 10px 0;
    @media (max-width: 768px) {
        align-self: start;
        font-size: 14px;
    }
    @media (max-height: 675px) {
        font-size: 20px;
        align-self: start;
    }
    @media (max-height: 675px) {
        font-size: 16px;
        align-self: start;
    }
    @media (max-height: 385px) {
        font-size: 12px;
    }
    @media (max-width: 320px) {
        font-size: 12px;
    }
`

const Menu = styled.p`
    justify-self: end;
    align-self: center;
    padding-right: 20px;
    font-weight: 400;
    font-size: 16px;
    grid-area: 1 / 1 / 3 / 2;
    @media (max-width: 768px) {
        display: none;
    }
`

const Main = styled.div`
    overflow: visible;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 3fr;
    padding: 0 75px;
    @media (max-width: 1180px) {
        padding: 0;
        grid-template-columns: 0fr 1fr 0fr 1fr;
    }
    @media (max-width: 768px) {
        grid-template-rows: 0px 45% 0px 55%;
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

const BottomMain = styled(Main)`
    grid-template-columns: 3fr 1fr 1fr 3fr;
    padding: 0 0 0 75px;
    @media (max-width: 768px) {
        padding: 0;
    }
`

const InfoDiv = styled.div`
    display: grid;
    grid-template-rows: 50% 50%;
    align-items: end;
    margin-left: 25px;
    padding-right: 100px;
    height: 100%;
    z-index: 1;
    @media (max-height: 725px) {
        align-items: start;
    }
    @media (max-width: 768px) {
        align-items: start;
        margin-left: 0px;
        padding-right: 0px;
    }
`

const PrimaryNumber = styled.p`
    font-family: Berlingske Serif;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
    margin-left: 4px;
    @media (max-width: 768px) {
        font-size: 30px;
    }
    @media (max-height: 675px) {
        font-size: 20px;
        align-self: start;
    }
    @media(max-height: 550px) {
        font-size: 18px;
    }
`

const RedText = styled.p`
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-align: justify;
    color: #c76306;
    margin: 5px;
    @media (max-width: 1400px) {
        letter-spacing: 1px;
    }
    @media (max-width: 1335px) {
        letter-spacing: normal;
    }
    @media (max-width: 768px) {
        font-size: 13px;
        letter-spacing: normal;
    }
    @media (max-height: 490px) {
        font-size: 14px;
    }
`

const SusGoal = styled.p`
    font-family: Work Sans;
    font-size: 13px;
    color: #979797;
    margin: 5px 6px 20px 6px;
`

const OutOfWhich = styled(SusGoal)`
    font-size: 16px;
    margin-bottom: 5px;
    @media (max-width: 768px) {
        font-size: 13px;
        margin-top: 30px;
    }
`

const GreyNumber = styled(SusGoal)`
    font-size: 28px;
    font-weight: bold;
    font-family: Berlingske Serif;
    margin: 0;
    margin-left: 4px;
    @media (max-width: 768px) {
        font-size: 20px;
    }
    @media (max-height: 675px) {
        font-size: 20px;
        align-self: start;
    }
    @media(max-height: 550px) {
        font-size: 16px;
    }
`

const GreyDescription = styled(SusGoal)`
    font-size: 14px;
    margin: 5px;
    @media (max-width: 768px) {
        font-size: 13px;
        letter-spacing: normal;
    }
    @media(max-height: 550px) {
        font-size: 12px;
    }
`

const Explore = styled.p`
    position: absolute;
    padding: 10px 30px;
    border-radius: 25px;
    align-self: center;
    justify-self: center;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    background-color: #ebaa00;
    cursor: pointer;
    @media (max-width: 768px) {
        align-self: end;
        margin-bottom: 20px;
    }
`

const StyledLogo = styled(Logo)`
    widht: 8vh;
    height: 8vh;
    @media (max-width: 768px) {
        grid-area: 1 / 1 / 3 / 2
    }

`

const DesktopOn = styled.div`
    align-self: center;
    grid-area: 1 / 1 / 3 / 2;
    @media(max-width: 768px) {
        display: none;
    }
    @media(max-height: 590px) {
        grid-area: 1 / 1 / 3 / 2;
        align-self: center;
    }
    @media(max-height: 450px) {
        display: none;
    }
    
`

const MobileOn = styled.div`
    align-self: start;
    display: none;
    @media(max-width: 768px) {
        margin-top: 20px;
        display: block;
    }
`

const RuralBar = styled.div`
    height: 35px;
    width: 100%;
    background-color: #ebaa00;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: start;
    color: white;
    border-radius: 25px;
    font-size: 15px;
    @media(max-height: 500px) {
        height: 15px;
        font-size: 10px;
    }
    @media (max-width: 500px) {
        height: 20px;
    }
`

const UrbanBar = styled.div`
    height: 35px;
    width: 100%;
    background-color: #c76306;
    display: grid;
    grid-template-columns: ${x => x.rural}% 1fr;
    align-items: center;
    color: white;
    border-radius: 25px;
    font-size: 15px;
    @media(max-height: 500px) {
        height: 15px;
        font-size: 10px;
    }
    @media (max-width: 500px) {
        height: 20px;
    }
`

const PlantLottie = styled(Lottie)`
    bottom: -5px;
    @media (max-height: 675px) {
        width: 70%;
        height: auto;
    }
`

const TopLottie = styled(Lottie)`
    @media (max-height: 550px) {
        height: 70%;
    }
    @media (max-height: 780px) {
        height: 70%;
    }
`

function Start(props) {

    const [fiesTicker, updateFies] = useState('')
    const [stuntingTicker, updateStunting] = useState('')
    const [ratios, changeRatios] = useState([[1, 1, 1],[1, 1, 1]])

    useEffect(() => {
        if (!(props.urban == null || props.rural == null || props.both == null)) {
            let total2020Fies = props.calculateTotal('fies', 'All Countries', 'countries', props.both, '2020')
            let total2025Fies = props.calculateTotal('fies', 'All Countries', 'countries', props.both, '2025')
            let rateOfChangeFies = (total2025Fies - total2020Fies) / (365 * 24 * 60 * 60)

            let t1 = new Date(2020, 7, 2, 0, 0, 0, 0)
            let t2 = new Date()
            let secFromJuly = Math.abs((t1.getTime() - t2.getTime()) / 1000)

            let currentFies = Math.ceil(total2020Fies + (secFromJuly * rateOfChangeFies))

            updateFies(currentFies)
            setInterval(function() {
                updateFies(currentFies += rateOfChangeFies/Math.abs(rateOfChangeFies))
            },Math.abs(1000 / rateOfChangeFies));

            let total2020Stunting = props.calculateTotal('stunting', 'All Countries', 'countries', props.both, '2020')
            let total2025Stunting = props.calculateTotal('stunting', 'All Countries', 'countries', props.both, '2025')
            let rateOfChangeStunting = (total2025Stunting - total2020Stunting) / (365 * 24 * 60 * 60)

            let currentStunting = Math.ceil(total2020Stunting + (secFromJuly * rateOfChangeStunting))
            updateStunting(currentStunting)
            setInterval(function() {
                updateStunting(currentStunting += rateOfChangeStunting/Math.abs(rateOfChangeStunting))
            },Math.abs(1000 / rateOfChangeStunting));

            calculateRatios()
        }
    }, [props.urban, props.rural, props.both])

    const calculateRatios = () => {
        changeRatios([[props.calculateTotal('fies', 'All Countries', 'countries', props.rural, '2020'),
        props.calculateTotal('fies', 'All Countries', 'countries', props.urban, '2020'),
        props.calculateTotal('fies', 'All Countries', 'countries', props.both, '2020')],
        [props.calculateTotal('stunting', 'All Countries', 'countries', props.rural, '2020'),
        props.calculateTotal('stunting', 'All Countries', 'countries', props.urban, '2020'),
        props.calculateTotal('stunting', 'All Countries', 'countries', props.both, '2020')]])
    }

    return (
        <StartDiv visible={props.visible}>
            <Header>
                <div style={{gridArea: "1 / 1 / 3 / 2", alignSelf: "stretch", justifySelf: "stretch", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <a href="https://worlddata.io"><StyledLogo></StyledLogo></a>
                </div>
                <HungerClock>World Hunger Clock</HungerClock>
                <ByWDL>by WORLD DATA LAB</ByWDL>
                <MenuDiv>
                    <Menu>MENU</Menu>
                    <Burger menuOpen={props.data.menuOpen} setMenuOpen={props.setMenuOpen}></Burger>
                </MenuDiv>
            </Header>
            <Main>
                    <TopLottie loop={true} play={true} animationData={Clock} rendererSettings= {{preserveAspectRatio: 'xMinYMin meet', className: "animation"}} style={{display: "flex", overflow: 'visible'}}/>
                <InfoDiv>
                    <div>
                        <PrimaryNumber>{fiesTicker.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</PrimaryNumber>
                        <RedText>PEOPLE LIVE IN MODERATE AND SEVERE FOOD INSECURITY</RedText>
                        <SusGoal>sustainable development goal indicator 2.1.2</SusGoal>
                        <div style={{display: 'flex', heigth: '25px'}}><UrbanBar rural={(ratios[0][0] / ratios[0][2]) * 100}><RuralBar>rural {((ratios[0][0] / ratios[0][2]) * 100).toFixed(0)}%</RuralBar><div style={{display: "flex", justifyContent:"center"}}>urban {((1 - (ratios[0][0] / ratios[0][2])) * 100).toFixed(0)}%</div></UrbanBar></div>
                    </div>
                    <div>
                        <OutOfWhich>out of which</OutOfWhich>
                        <GreyNumber>819,716,946</GreyNumber>
                        <GreyDescription>people live in severe food insecurity</GreyDescription>
                    </div>
                </InfoDiv>
                    <TopLottie loop={true} play={true} animationData={GirlMeasuring} rendererSettings= {{preserveAspectRatio: 'xMinYMin meet', className: "animation"}} style={{display: "flex", overflow: 'visible'}}/>
                <InfoDiv>
                    <div>
                        <PrimaryNumber>{stuntingTicker.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</PrimaryNumber>
                        <RedText>CHILDREN UNDER THE AGE OF 5 ARE STUNTED</RedText>
                        <SusGoal>sustainable development goal indicator 2.2.1</SusGoal>
                        <div style={{display: 'flex', heigth: '25px'}}><UrbanBar rural={(ratios[1][0] / ratios[1][2]) * 100}><RuralBar>rural {((ratios[1][0] / ratios[1][2]) * 100).toFixed(0)}%</RuralBar><div style={{display: "flex", justifyContent:"center"}}>urban {((1 - (ratios[1][0] / ratios[1][2])) * 100).toFixed(0)}%</div></UrbanBar></div>
                    </div>
                    <MobileOn>
                        <GreyNumber>798,400,000</GreyNumber>
                        <GreyDescription>PEOPLE IN THE WORLD ARE UNDERNOURISHED</GreyDescription>
                        <SusGoal>sustainable development goal indicator 2.1.1</SusGoal>
                    </MobileOn>
                </InfoDiv>
            </Main>
            <BottomMain>
                <InfoDiv>
                    <DesktopOn>
                        <GreyNumber>798,400,000</GreyNumber>
                        <GreyDescription>people in the world are undernourished</GreyDescription>
                        <SusGoal>sustainable development goal indicator 2.1.1</SusGoal>
                    </DesktopOn>
                </InfoDiv>
                <div />

                <div />
                <div style={{display: 'grid', position: 'relative'}}>
                    <PlantLottie loop={true} play={true} animationData={Plants} rendererSettings= {{preserveAspectRatio: 'xMaxYMax meet'}} style={{display: "flex", position: 'absolute', right:"0", overflow: 'visible', zIndex: "0"}}/>
                    <Explore
                        onClick={() => {
                            console.log('here')
                            const container = document.querySelector('#container');
                            container.scrollTo({left: 0, top: window.innerHeight + 10, behavior: 'smooth'})
                            }}>
                        EXPLORE THE DATA
                    </Explore>
                </div>
            </BottomMain>
        </StartDiv>
    )
}

export default Start