import React, {useState, useRef, useEffect } from 'react'
import L from 'leaflet';
import {Map, Popup, Marker, TileLayer, GeoJSON } from 'react-leaflet'
import geobuf from 'geobuf'
import Pbf from 'pbf'
import live from './data/live.json'
import localGeoJson from './data/localGeoData.json'
import countryBorders from './data/countryBorders.json'    
import bbox from '@turf/bbox';
import MarkerClusterGroup from 'react-leaflet-markercluster'
import './assets/css/style.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'leaflet/dist/leaflet.css'
import IsoCoord from './data/IsoCoord.json'
import AreaIso from './data/AreaIso.json'
import IsoArea from './data/IsoArea.json'
import GdlArea from './data/GdlArea.json'
import styled from 'styled-components'
import { ReactComponent as Stalk } from './assets/img/Stalk.svg'
import { ReactComponent as House } from './assets/img/House.svg'
import { ReactComponent as Clock } from './assets/img/Clock.svg'
import spinner from './assets/img/loadingIcon.gif'

import { ReactComponent as StickFigure } from './assets/img/StickFigure.svg'


/**
 * This is the map
 */


const RequestData = styled.div`
    width: 137px;
    height: 55px;
    position: absolute;
    right: 0;
    top: 10vh;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 15% 50%);
    background-color: black;
    grid-area: 5 / 1 / 6 / 2;
    display: flex;
    align-items: center;
    justify-content:  center;
    text-align: right;
    font-size: 14px;
    cursor: pointer;
    @media (max-width: 1250px) {
        width: 100px;
    }
    @media (max-width: 811px) {
        display: none;
    }
`

const BarDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-area: 2 / 2 / 3 / 5;
    border-radius: 9px;
    overflow: visible;
`

const Bar = styled.div`
    display: flex;
    align-self: center;
    height: 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    border-radius: 9px;
    background-image: linear-gradient(to right, #d7d7d7, #ebaa00);
    overflow: visible;
`

const Tick = styled.div`
    display: block;
    width: 1px;
    height: 25px;
    margin-left: ${props => props.margin}%;
    background-color: black;
`

const TrackDiv = styled.div`
    display: flex;
    grid-area: 2 / 2 / 3 / 5;
    align-items: center;
    justify-content: center;
`

const Dot = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${x => x.color};
    margin: 10px;
`

const NewStyledPop = styled(Popup)`
    border-radius: 0;
    transition: none;
    .leaflet-popup-content-wrapper {
        width: 400px;
        border-radius: 0;
        background-color: black;
    }

    .leaflet-popup-content {
        display: grid;
        margin: 10px;
        width: 380px;
        height: 180px;
        grid-template-columns: 90px 90px 200px;
        grid-template-rows: 50px 70px 40px;
        grid-gap: 5px;
        font-family: Work Sans;
    }

    .leaflet-popup-content p {
        margin: 0;
    }

    .leaflet-popup-tip-container {
        visibility: hidden;
    }

    .leaflet-popup-close-button {
        @media (max-width: 768px) {
            visibility: visible;
        }
    }
`

const NameDiv = styled.div`
    grid-area: ${x => x.gridArea};
    display: flex;
    align-items: center;
`

const PopupName = styled.p`
    font-family: Berlingske Serif;
    color: #ebaa00;
    margin: 0;
`

const AreaInfoDiv = styled.div`
    grid-area: ${x => x.gridArea};
    display: grid;
    grid-gap: 0px 6px;
    grid-template-rows: 65% 35%;
    grid-template-columns: 20% 80%;
`

const OutOfP = styled.p`
    color: ${x => x.color};
    grid-area: ${x => x.gridArea};
    text-align: left;
    justify-content: start;
`

const AreaPercent = styled.p`
    color: ${x => x.color};
    font-size: 18px;
    justify-self: start;
    font-weight: bold;
`

const TimeP = styled(AreaPercent)`
    font-size: 14px;
`

const Description = styled.p`
    color: ${x => x.color};
    font-size: 12px;
    justify-self: start
`

const UrbDiv = styled.div`
    display: grid;
    height: 60px;
    grid-template-rows: 30% 70%;
    color: white;
    grid-area: ${x => x.gridArea};
`

const InnerUrban = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 30% 60%;
`

const SdgDiv = styled.div`
    display: grid;
    grid-area: 2 / 3 / 3 / 4;
    width: 100%;
    height: 60px;
    grid-template-rows: 20% 20% 40% 20%;
    grid-template-columns: 50% 50%;
`

const PopupCountryName = styled.p`
    font-family: Berlingske Serif;
    color: #979797;
` 

const LivePopup = styled(Popup)`
    border-radius: 0;
    transition: none;
    .leaflet-popup-content-wrapper {
        border-radius: 0;
        background-color: black;
    }

    .leaflet-popup-content {
        display: grid;
        width: 260px;
        height: 180px;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    }

    .leaflet-popup-content p {
        margin: 0;
    }

    .leaflet-popup-tip-container {
        visibility: hidden;
    }

    .leaflet-popup-close-button {
        visibility: visible;
    }
`

const PopupLabel = styled.p`
    grid-area: ${x => x.gridArea};
    color: ${x => x.selected ? 'white' : '#c76306'};
    justify-self: start;
    margin: 0px;
    align-self: center;
`

const PopupData = styled(PopupLabel)`
    font-weight: bold;
`

const MapLayout = styled.div`
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 4fr 1fr 1fr;
    grid-template-rows: 1fr 50px;
    height: 70vh;
    @media (max-width: 811px) {
        grid-template-columns: 0px 1fr 4fr 1fr 0px;
    }
`

const LivePopupText = styled.p`
    color: white;
    justify-self: start;
    align-self: center;
    font-size: 12px;
    letter-spacing: 2px;
    text-decoration: none;
`

const MapArea = styled.div`
    position: relative;
`

const BarNumber = styled.p`
    alignSelf: "center",
    margin: "10px",
    justifySelf: ${x => x.justifySelf};
`

const position = [40, 0]

const bounds = [[-200, -300], [200, 300]]

const sdgColorArray = ['#d7d7d7aa', '#00a046aa', '#eba900aa', '#ee4322aa']

const SdgTrack = ["MEETS SDG", "ON TRACK", "OFF TRACK", "RISING"]

const BrownIcon = L.divIcon({
    html:
        `<div class="brownIcon"></div>`,
    className: ``,
    iconAnchor: [15, 32],
})

const RedIcon = L.divIcon({
    html:
        `<div class="redIcon"></div>`,
    className: ``,
    iconAnchor: [15, 32],
})

const BlackIcon = L.divIcon({
    html:
        `<div class="BlackIcon"></div>`,
    className: ``,
    iconAnchor: [15, 32],
}) 

let hungryArea
let totalHungry
let maxAbsolute
let maxPercentCountry
let maxPercentGlobal

function setup(props, data) {

    //map all admin1 areas to their scarcity number
    hungryArea = Object.keys(data[props.data.year])
    .reduce((r, k) => {
        return r.concat(Object.values(data[props.data.year][k]));
    }, [])
    .map(x => x[props.data.scarcity])

    // calculate the total sum of all admin1 areas
    totalHungry = hungryArea
    .filter(x => typeof(x) === 'number')
    .reduce((a, b) => a + b, 0)

    //find the maximum of all admin1 areas
    maxAbsolute = hungryArea
    .reduce((a, c) => {
        return a < c ? c : a
    })

    //find the admin1 area with the highest percentage of hungry people
    maxPercentCountry = Object.keys(data[props.data.year])
    .reduce((r, k) => {
    return r.concat(Object.values(data[props.data.year][k]));
    }, [])
    if (props.data.scarcity === 'fies') {
        maxPercentCountry = maxPercentCountry.reduce((a, c) => {
            return a < c[props.data.scarcity] / c['population'] ? c[props.data.scarcity] / c['population'] : a
        }, 0)
    } else {
        maxPercentCountry = maxPercentCountry.reduce((a, c) => {
            return a < c[props.data.scarcity] / c['u5pop'] ? c[props.data.scarcity] / c['u5pop'] : a
        }, 0)
    }

    // the country with the highest percentage of the worlds hungry people
    maxPercentGlobal = maxAbsolute / totalHungry
}

const customIconFunction = (cluster) =>{
    const count = cluster.getChildCount();
    const sourceSum = cluster.getAllChildMarkers().reduce((a, c) => a + c.options.source, 0)
    let source = "Fews"
    // if (count < 30) {
    //     source = 'Fews'
    // } else if (count < 60) {
    //     source = 'Livehungermap'
    // } else {
    //     source = 'Both'
    // }
    return L.divIcon({
        html: `<div><span class="markerClusterLabel">${count}</span></div>`,
        className: `markerCluster${source}`
    })
}

const liveStyle = () => {
    return {
        fillColor: "#c76306",
        weight: 2,
        opacity: 1,
        color: "#c76306",
        fillOpacity: .5
    }
}


function HungerMap(props) {

    let [geoJSON, setGeoJson] = useState()

    let [markers, setMarkers] = useState()

    let [activeLive, setActiveLive] = useState()

    let [Popup, setPopup] = useState(false)

    let [PopupInfo, setPopupInfo] = useState()

    let [livePopup, setLivePopup] = useState(false)
    
    let [livePopupInfo, setLivePopupInfo] = useState({latlng: null})

    let [minLabel, setMinLabel] = useState('0')
    let [maxLabel, setMaxLabel] = useState()

    let [data, setData] = useState(null)

    let [highlightedCountry, setHighlightedCountry] = useState("")

    const mapRef = useRef(null)
    const countryRef = useRef(null)

    useEffect(() => {
        setMarkers(live.features
            .filter(x => x.properties.CS >= 3 && x.properties.CS <=5)
            .map((x, i) => {
                return <Marker onClick={x => {
                    setLivePopup(false)
                    setActiveLive(null)
                    setActiveLive(x.target.options.geo)
                    showLiveCard(x)}} geo={x} source={x.properties.source} icon={x.properties.CS === 3 ? BrownIcon : (x.properties.CS === 4 ? RedIcon : BlackIcon) } key={i} position={[x.properties.centroid[0][1],x.properties.centroid[0][0]]}></Marker>
            }))
    }, [])

    useEffect(() => {
            fetch('http://localhost:1234/file/data')
            .then((resp) => resp.arrayBuffer())
            .then((data) => {
            var geojson = geobuf.decode(new Pbf(data))
            setGeoJson(geojson)
            })
            .catch(x => setGeoJson(localGeoJson))
    }, [])

    useEffect(() => {
        setData(props.data.hungerData)
        setup(props, props.data.hungerData)
        setMinLabel(props.data.lense === "Absolute Hunger" ? "0" : "0%")
        setMaxLabel(props.data.lense === "Absolute Hunger" ? maxAbsolute.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (props.data.lense === "Relative Hunger" ? (maxPercentCountry * 100).toFixed(1) + "%" : (maxPercentGlobal * 100).toFixed(1) + "%"))
    }, [props])

    const countryStyle = (x) => {
        return{
            weight: x.properties.GID_0 === highlightedCountry ? 3.5 : 1.5,
            opacity: 1,
            fillOpacity: 0,
            color: x.properties.GID_0 === highlightedCountry ? "#222222" : "#aaaaaa"
        }
    }

    const color = (gdlCode) => {
        if (data[props.data.year][gdlCode.substring(0, 3)] != null) {
            let area = data[props.data.year][gdlCode.substring(0, 3)][gdlCode]
            if (area == null || area[props.data.scarcity] === 'NA') {
                return 'rgba(0, 0, 0, 0)'
            }
            let ratio
            switch (props.data.lense) {
                case 'Absolute Hunger':
                    ratio = area[props.data.scarcity] / maxAbsolute
                    break
                case 'Relative Hunger':
                    if (area['fies'] === 0 && area['stunting'] === 0 && area['population'] === 0 && area['u5pop'] === 0) {
                        return ('rgba(0, 0, 0, 0)')
                    }
                    let pop = props.data.scarcity === "fies" ? "population" : "u5pop"
                    ratio = area[props.data.scarcity] / area[pop]
                    break
                case 'SDG Tracks':
                    let sdgTrack = props.data.scarcity === 'fies' ? 'stable_track_fies' : 'stable_track_stunting'
                    return sdgColorArray[area[sdgTrack]]
                    break
                default:
                    break
            }
            return `rgba(235, 170, 0, ${ratio})`
        } else {
            return 'rgba(0, 0, 0, 0)'
        }
    }
    
    const admin1Style = (feature) => {
        return {
            fillColor: color(feature.properties.GDLcode),
            weight: 1,
            opacity: 0.5,
            color: '#aaaaaa',
            fillOpacity: 1
        }
    }
    useEffect(() => {
        const selectedCountry = countryBorders.features.find(country => country.properties.GID_0 === AreaIso[props.data.viewSelected])
        if (mapRef.current != null) {
        if (props.data.viewSelected.includes("All")) {
            mapRef.current.leafletElement.setView(position, 2)
        } else if (selectedCountry != null){
            console.log(selectedCountry)
            if (selectedCountry == null || selectedCountry.geometry == null) {
                return
            }
            setHighlightedCountry(AreaIso[props.data.viewSelected])
            const selectedCountryBounds = bbox(selectedCountry);
            const corner1 = [selectedCountryBounds[1],selectedCountryBounds[0]]
            const corner2 = [selectedCountryBounds[3],selectedCountryBounds[2]]
            const leafletCountryBounds = [corner1,corner2]
            setHighlightedCountry(AreaIso[props.data.viewSelected])
            mapRef.current.leafletElement.flyToBounds(leafletCountryBounds)
        }
    }
    }, [props.data.viewSelected])

    const showCountryCard = (x) => {
        setPopup(false)
        x.target.setStyle({fillColor: "blue"})
        let code = x.layer.feature.properties.GDLcode
        let countryName = code === "NA" ? "NA" : IsoArea[code.substring(0, 3)]
        console.log(code)
        let adminName = code === "NA" ? "NA" : GdlArea[code]
        setPopupInfo({latlng: [x.latlng.lat, x.latlng.lng], code: x.layer.feature.properties.GDLcode, countryName: countryName, adminName: adminName, numbers: newPopupNumber(code)})
        setPopup(true)
    }

    const showLiveCard = (x) => {
        setLivePopupInfo({latlng: [x.latlng.lat, x.latlng.lng], source: x.target.options.source, cs: x.target.options.cs})
        setLivePopup(true)
    }
    const newPopupNumber = (code) => {
        if (code === "NA" || code === "GRDt" || data[props.data.year][code.substring(0, 3)] == null || data[props.data.year][code.substring(0, 3)][code] == null || data[props.data.year][code.substring(0, 3)][code][props.data.scarcity] === "NA") {
            return ["NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA"]
        }
        let bothData = props.bothData[props.data.year][code.substring(0, 3)][code]
        let ruralData = props.ruralData[props.data.year][code.substring(0, 3)][code]
        let urbanData = props.urbanData[props.data.year][code.substring(0, 3)][code]
        let res = []
        res[0] = `${(bothData[props.data.scarcity] / (props.data.scarcity === 'fies' ? bothData['population'] : bothData['u5pop']) * 100).toFixed(0)}%`
        res[1] = `${bothData[props.data.scarcity].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        res[2] = `${(props.data.scarcity === 'fies' ? bothData['population'] : bothData['u5pop']).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        res[3] = `${((ruralData[props.data.scarcity] / bothData[props.data.scarcity]) * 100).toFixed(0)}%`
        res[3] = res[3] === 'NaN%' ? '0' : res[3]
        res[4] = `${((urbanData[props.data.scarcity] / bothData[props.data.scarcity]) * 100).toFixed(0)}%`
        res[4] = res[4] === 'NaN%' ? '0' : res[4]
        res[5] = props.data.scarcity === "fies" ? bothData['stable_track_fies'] : bothData['stable_track_stunting']
        res[6] = escapeRate(code)
        res[7] = bottomLabel(res[6])
        res[6] = res[7].includes("escape") ? res[6].substring(1, res[6].length) : res[6]
        let temp = countryAggregation(code)
        res[8] = temp[0]
        res[9] = temp[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        res[10] = temp[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return res
    }

    const escapeRate = (code) => {
        let res
        let curYear = props.data.year === '2030' ? '2025' : props.data.year
        let nextYear = props.data.year === '2020' ? '2025' : '2030'
        let totalNow = props.bothData[curYear][code.substring(0, 3)][code][props.data.scarcity]
        let totalNext = props.bothData[nextYear][code.substring(0, 3)][code][props.data.scarcity]
        let diff = totalNext - totalNow
        let sec = 5 * 365 * 24 * 60 * 60
        let per = diff / sec
        if (diff === 0) {
            return `NA`
        }
        if (Math.abs(per) < 1) {
            let min = sec / 60
            per = diff / min
            res = `${per.toFixed(1)} people per min`
            console.log(per)
        } if (Math.abs(per) < 1) {
            let hours = (sec / 60) / 60
            per = diff / hours
            res = `${per.toFixed(1)} people per hour`
        } if (Math.abs(per) < 1) {
            let days = sec / 60 / 60 / 24
            per = diff / days
            res = `${per.toFixed(1)} people per day`
        } if (Math.abs(per) < 1) {
            let weeks = sec / 60 / 60 / 24 / 7
            per = diff / weeks
            res = `${per.toFixed(1)} people per week`
        }
        return res
    }

    const bottomLabel = (temp) => {
        if (temp.includes('-')) {
            return props.data.scarcity === "fies" ? "escape food insecurity" : "escape stunting"
        } else {
            return props.data.scarcity === "fies" ? "becomes food insecure" : "becomes stunted"
        }
    }

    const countryAggregation = (code) => {
        let res = []
        let list = Object.values(props.bothData[props.data.year][code.substring(0, 3)])
        let hungry = list.map(x => x[props.data.scarcity]).reduce((a, c) => a + c, 0)
        let popName = props.data.scarcity === 'fies' ? 'population' : 'u5pop'
        let pop = list.map(x => x[popName]).reduce((a, c) => a + c, 0)
        res[0] = `${((hungry / pop) * 100).toFixed(0)}%`
        res[1] = hungry
        res[2] = pop
        return res
    }

    const tickLocation = () => {
        if (PopupInfo == null || PopupInfo.code === "NA" || data[props.data.year][PopupInfo.code.substring(0, 3)] == null) {
            return 0
        }
        let margin
        let pop = props.data.scarcity === 'fies' ? 'population' : 'u5pop'
        switch (props.data.lense) {
            case 'Absolute Hunger':
                margin= data[props.data.year][PopupInfo.code.substring(0, 3)][PopupInfo.code][props.data.scarcity] / maxAbsolute
                break
            case 'Relative Hunger':
                margin = (data[props.data.year][PopupInfo.code.substring(0, 3)][PopupInfo.code][props.data.scarcity] / data[props.data.year][PopupInfo.code.substring(0, 3)][PopupInfo.code][pop]) / maxPercentCountry
                break
            case 'Relative to World':
                //TODO Tick Location
                margin = data[props.data.year][PopupInfo.code.substring(0, 3)][PopupInfo.code][props.data.scarcity] / totalHungry / maxPercentGlobal
                break
            default:
                break
        }
        return margin * 100 - 0.25
    }

    const liveMainText = () => {
        if (livePopupInfo.source === 1) {
            switch (activeLive.properties.CS) {
                case 3:
                    return "crisis"
                case 4:
                    return "emergency"
                case 5:
                    return "famine"
                default:
                    return `${activeLive.properties.CS}`
            }
            // return activeLive.properties.CS === 3 ? "crisis" : activeLive.properties.CS === 4 ? "emergency" : "famine"
        }
        return `${(activeLive.properties.CS * 100).toFixed(1)}%`
    }

    return (
        <MapArea>
            {geoJSON != null ? (
        <MapLayout>
            <RequestData onClick={() => props.setRequestData(true)}><p style={{color: 'white'}}> REQUEST<br/>DATA</p></RequestData>
            {geoJSON != null && <Map style={{
                gridColumnStart: "2", 
                gridColumnEnd: "5", 
                height:"100%", 
                alignSelf:"stretch",
                }} 
                ref={mapRef}
                useFlyTo={true}
                center={position} 
                zoom={2}
                maxZoom={18}
                minZoom={2}
                maxBounds={bounds}
                fadeAnimation={false}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>' 
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png"
                subdomains="abcd"
                />
                <GeoJSON
                     data={countryBorders}
                     style={countryStyle}
                     ref={countryRef}/>
                <GeoJSON 
                    data={geoJSON} 
                    style={admin1Style}
                    onMouseOver={showCountryCard}
                    onClick={(x) => {showCountryCard(x); props.changeViewSelected(IsoArea[x.layer.feature.properties.GDLcode.substring(0, 3)])}}
                    onMouseOut={() => setPopup(false)}
                />
                {Popup && 
                    <NewStyledPop
                        autoPan={false}
                        position={PopupInfo.latlng}
                        closeButton={true}>
                        <NameDiv gridArea="1 / 1 / 2 / 3">
                            <PopupName style={{fontSize: PopupInfo.adminName.length > 20 ? (PopupInfo.adminName.length > 35 ? '8pt' : '12pt') : "16pt"}}>{PopupInfo.adminName}</PopupName>
                        </NameDiv>
                        <AreaInfoDiv gridArea="1 / 3 / 2 / 4">
                            <AreaPercent color="white">{PopupInfo.numbers[0]}</AreaPercent>
                            <Description color="white">of the population is {props.data.scarcity === "fies" ? 'food insecure' : 'stunted'}</Description>
                            <OutOfP color="#ebaa00" gridArea="2 / 1 / 3 / 3" style={{justifySelf: 'start'}}>{PopupInfo.numbers[1]} out of {PopupInfo.numbers[2]}</OutOfP>
                            {/* <OutOfP color="#c76306" gridArea="2 / 2 / 3 / 3">out of {PopupInfo.numbers[2]}</OutOfP> */}
                        </AreaInfoDiv>
                        {/* <div style={{gridArea: "2 / 1 / 3 / 4", borderBottom: "1px solid white", paddingBottom: "5px", display: "grid", gridTemplateColumns: "90px 90px 180px"}}> */}
                            <UrbDiv gridArea="2 / 1 / 3 / 2"><p>RURAL</p><InnerUrban><Stalk /><p style={{fontSize: "16px", fontWeight: "bold"}}>{PopupInfo.numbers[3]}</p></InnerUrban></UrbDiv>
                            <UrbDiv style={{borderRight: "1px solid white"}} gridArea="2 / 2 / 3 / 3"><p>URBAN</p><InnerUrban><House /><p style={{fontSize: "16px", fontWeight: "bold"}}>{PopupInfo.numbers[4]}</p></InnerUrban></UrbDiv>
                            <SdgDiv>
                                {/* <p style={{color: sdgColorArray[PopupInfo.numbers[5]]}}>{SdgTrack[PopupInfo.numbers[5]]}</p> */}
                                <p style={{color: sdgColorArray[PopupInfo.numbers[5]]}}></p>
                                {/* <p style={{color: "white"}}>SDG 2.1</p> */}
                                <p style={{color: "white"}}></p>
                                <p style={{color: "#979797", textAlign: "start"}}>rate:</p>
                                <div />
                                <div style={{display: "flex", alignItems: "center", gridArea: "3 / 1 / 4 / 3"}}>
                                    <Clock/><TimeP color="white">&ensp;{PopupInfo.numbers[6]}</TimeP>
                                </div>
                                {/* <div style={{display: "flex", alignItems: "center"}}>
                                    <StickFigure /><TimeP color="white">&ensp;1 person</TimeP>
                                </div> */}
                                <p style={{color: "#979797", gridArea:"4 / 1 / 5 / 3"}}>{PopupInfo.numbers[7]}</p>
                            </SdgDiv>
                        {/* </div> */}
                        <div style={{gridArea: "3 / 1 / 4 / 4", display: "grid", gridTemplateColumns: "90px 90px 200px", gridGap: "5px", borderTop: "1px solid white", width: "96%", paddingTop: "3px"}}>
                            <NameDiv gridArea="1 / 1 / 2 / 3"><PopupCountryName style={{fontSize: PopupInfo.countryName.length > 20 ? (PopupInfo.countryName.length > 35 ? '8pt' : '12pt') : "15pt"}}>{PopupInfo.countryName}</PopupCountryName></NameDiv>
                            <AreaInfoDiv gridArea="1 / 3 / 2 / 4">
                                <AreaPercent color="#979797">{PopupInfo.numbers[8]}</AreaPercent>
                                <Description color="#979797">of the population is {props.data.scarcity === "fies" ? 'food insecure' : 'stunted'}</Description>
                                <OutOfP color="#979797" gridArea="2 / 1 / 3 / 3" style={{justifySelf: 'start'}}>{PopupInfo.numbers[9]} out of {PopupInfo.numbers[10]}</OutOfP>
                                {/* <OutOfP color="#979797" gridArea="2 / 2 / 3 / 3">out of {PopupInfo.numbers[10]}</OutOfP> */}
                            </AreaInfoDiv>
                        </div>
                    </NewStyledPop>
                }
                {activeLive != null && 
                    <GeoJSON 
                    data={activeLive} 
                    style={liveStyle}
                    />
                }
                {props.data.live === 'on' && 
                <MarkerClusterGroup
                disableClusteringAtZoom={11}
                maxClusterRadius={30}
                showCoverageOnHover={false}
                    iconCreateFunction={customIconFunction}
                    >
                    {markers}
                </MarkerClusterGroup>
                }
                {livePopup &&
                    <LivePopup
                        onClose={() => {setLivePopup(false); setActiveLive(null)}}
                        autoClose={false}
                        autoPan={false}
                        position={livePopupInfo.latlng}>
                            <LivePopupText>LIVE MODE CARD</LivePopupText>
                            <LivePopupText>{livePopupInfo.source === 1 ? "The Famine Early Warning System (FEWS) considers this territory as a territory that is in the" : "The Hunger Map Live system estimates that:" }</LivePopupText>
                            <p style ={{
                                color:"#ebaa00",
                                justifySelf:"start",
                                margin: "0",
                                alignSelf:"center",
                                fontSize:"25px",
                                fontWeight: "bold",
                                letterSpacing:"2px"}}>{liveMainText()}</p>
                            <LivePopupText>{livePopupInfo.source === 1 ? 'acute food insecurity phase' : 'Of people in this area are currently experiencing insufficient food consumption.'} </LivePopupText>
                            <LivePopupText as="a" href={livePopupInfo.source === 1 ? "https://fews.net/" : "https://hungermap.wfp.org/"} style={{color: 'white', borderBottom: "1px solid white"}}>LEARN MORE</LivePopupText>
                    </LivePopup>
                }
            </Map>}
            {props.data.lense != "SDG Tracks" ?
                <BarDiv>
                    <BarNumber justifySelf="end">{minLabel}</BarNumber>
                    <Bar>{Popup && <Tick margin={tickLocation}/>}</Bar>
                    <BarNumber justifySelf="start">{maxLabel}</BarNumber>
                </BarDiv> :
                <TrackDiv>
                    <Dot color="#ee4322"/>
                    <p>{props.data.scarcity === "fies" ? "food insecurity" : "stunting"} is rising</p>
                    <Dot color="#eba900"/>
                    <p>off-track to reach SDG 2.1</p>
                    <Dot color="#00a046"/>
                    <p>on-track to reach SDG 2.1</p>
                    <Dot color="#d7d7d7"/>
                    <p>less than 3% hunger</p>
                    <Dot color="white" style={{border: "1px solid grey"}}/>
                    <p>no data</p>
                </TrackDiv>}

        </MapLayout>) : <div style={{height: "70vh", display: "flex", alignItems: "center", justifyContent: "center"}}><img src={spinner} /></div>}
        </MapArea>
    )
}

export default HungerMap