const env = process.env.NODE_ENV;

const config = {
  development: {
    apiUrl: 'https://86bl7glvt2.execute-api.eu-central-1.amazonaws.com/development',
    apiKey: '6ah6ijDwrP9OHvBbrUrDd1ciIGjl1buz7BcewQcD'
  },
  production: {
    apiUrl: 'https://api.worldhunger.io',
    apiKey: '117ZCrRW8k3bmRY9TPN1R6KGtQPVcrbB95oX3vzX'
  }
};

module.exports = config[env];
