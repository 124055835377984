import React, { useState } from 'react'
import styled from 'styled-components'
import { DropDown, DropDownItem } from './../assets/css/commonStyles.js'

/**
 * This is the dropdown for selecting the view
 */


/**
* The current selected view paragraph
*/

const Current = styled.p`
    grid-area: 2 / 1 / 3 / 3;
    align-self: center;
    text-align: left;
    border: 1px solid black;
    border-radius: 15px;
    padding: 5px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: black;
    background-color: white;
    text-decoration: none;
    transition: filter 300ms;
    margin: 0;
    &:hover {
        background-color: #F9F9F9;
    }

    &:: after {
        content: "v";
        position: absolute;
        bottom: 13px;
        right: 20px;
        font-size: 10px; 
        font-weight: normal;
        @media (max-height: 700px) {
            bottom: 6px;
        }
    }
`

const Text = styled.p`
    grid-area: 1 / 1 / 2 / 3;
    font-size: 14px;
    padding: 0;
    margin: 0;
    align-self: center;
    color: #4a2401;
    @media (max-height: 540px) {
        display: none;
    }
`

const DropDownDiv = styled.div`${DropDown}`

const DropDownElement = styled.p`${DropDownItem}`

function DropDownMenu(props) {

    const [open, setOpen] = useState(false);

    /**
     * Changes the view state when it is clicked
     * @param {Click} event 
     */
    const onClick = (event) => {
        props.changeCurrentView(event)
        props.changeViewData(event)
        setOpen(false)
    }

    return (
        <props.div> 
            <Text>CHANGE VIEW</Text>        
            <Current onClick={() => setOpen(!open)}>{props.currentView}</Current>
            
            {open && 
                <DropDownDiv top="8vh">
                    {props.data.map(x => <DropDownElement 
                    key={x}
                    weight={props.currentView === x ? 600 : 400}
                    onClick={() => onClick(x)}
                    >{x}</DropDownElement>)}
                </DropDownDiv>
            }
        </props.div>
    )

}

export default DropDownMenu