import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MobileDropDownDiv, Current, DropDown, DropDownItem } from './../assets/css/commonStyles.js'


/**
 * This is the drop down menu used for filtering on mobile devices
 */

const DropDownDiv = styled.div`${DropDown}`

const DropDownElement = styled.p`${DropDownItem}`


function MobileDropDown(props) {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(props.data)
    const [tempView, changeTempView] = useState(props.viewSelected)

    /**
     * Changes the displayed value in the mobile search bar
     */
    useEffect(() => {
        changeTempView(props.viewSelected)
    }, [props.viewSelected])

    /**
     * Changes the displayed value on a type event and changes the state of the application a full country name is entered.
     * @param {Typing} event 
     */
    const handleChange = (event) => {
        setOpen(true)
        changeTempView(event.target.value)
        let toLowerCase = event.target.value.toLowerCase();
        if (!props.data.includes(event.target.value)) {
            setData(props.data.filter(x => {
                return x.toLowerCase().includes(toLowerCase)
            }))
        } else {
            props.changeViewSelected(event.target.value)
            setData(props.data)
            setOpen(false)
        }
    }

    /**
     * Changes the state of the application when a country is clicked
     * @param {*} event 
     */
    const onClick = (event) => {
        changeTempView(event)
        props.changeViewSelected(event)
        setOpen(false)
    }


    return (
        <MobileDropDownDiv>
            <Current
                as="input"
                value={tempView}
                onChange={handleChange} 
                onClick={() => {
                        setOpen(!open)
                        setData(props.data)
                    }
                }/>
            {open && 
                <DropDownDiv top="10vh" style={{width: "80%"}}>
                    {data.map(x => 
                        <DropDownElement 
                            key={x}
                            weight={props.viewSelected === x ? 600 : 400}
                            onClick={() => onClick(x)}>
                            {x}
                        </DropDownElement>)}
                </DropDownDiv>
            }
        </MobileDropDownDiv>
    )

}

export default MobileDropDown