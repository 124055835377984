import React from 'react'
import styled from 'styled-components'
import Button from './components/RoundedButton'

/**
 * This is the menu where the filters, current mode, and lenses are selected
 */

const BottomMenuDiv = styled.div`
    height: 10vh;
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr 2fr 1fr 3fr 1fr;
    @media (max-width: 811px) {
        display: none;
    }
`

const TripleDiv = styled.div`
    height: 8vh;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
    width: 90%;
    display: grid;
    justify-self: center;
`

const Text = styled.p`
    grid-area: 1 / 1 / 2 / 4;
    padding: 0;
    margin: 0;
    align-self: start;
    font-size: 14px;
    color: #4a2401;
`
const RedDot = styled.div`
    margin: 0px;
    background: ${props => props.on};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    right: 2px;
    display: inline-block;
`

function BottomMenu(props) {

    return (
        <BottomMenuDiv>
            <div />
            <TripleDiv>
                <Text>CHOOSE A YEAR</Text>        
                <Button 
                    style={{borderRadius: '15px'}}
                    on="2020"
                    current={props.data.year}
                    primary="black"
                    secondary="white"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 1 / 3 / 2"
                    onClick={() => 
                        props.changeYear('2020')
                    }
                >2020</Button>
                <Button 
                    style={{borderRadius: "15px"}}
                    on="2025"
                    current={props.data.year}
                    primary="black"
                    secondary="white"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 2 / 3 / 3"
                    onClick={() => {
                        props.changeYear('2025')
                        props.changeLive('off')
                    }}
                >2025</Button>
                <Button 
                    style={{borderRadius: "15px"}}
                    on="2030"
                    current={props.data.year}
                    primary="black"
                    secondary="white"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 3 / 3 / 4"
                    onClick={() => {
                        props.changeYear('2030')
                        props.changeLive('off')
                    }}
                >2030</Button>
            </TripleDiv>
            <TripleDiv style={{padding: "0 5%", borderLeft: "1px solid #979797", borderRight: "1px solid #979797"}}>
            <Text>LIVE MODE</Text>        

                <Button 
                    style={{borderRadius: "15px"}}
                    on="on"
                    current={props.data.live} 
                    primary="red"
                    secondary={props.data.year === "2020" ? "white" : "#F0F0F0"}
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 1 / 3 / 4"
                    onClick={() => {
                        if (props.data.year === '2020') {
                            if (props.data.live === 'on') {
                                props.changeLive('off')
                            } else {
                                props.changeLive('on')
                            }
                        }
                    }}
                ><RedDot on={props.data.live === "on" ? "white" : "red"} />&ensp;crisis spots</Button>
            </TripleDiv>
            <TripleDiv style={{gridTemplateColumns: "1fr 1fr"}}>
                <Text>LENSES</Text> 
                <Button 
                    style={{borderRadius: "15px"}}
                    on="Relative Hunger"
                    current={props.data.lense}
                    primary="#c76306"
                    secondary="rgba(199, 99, 6, 0.02)"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 1 / 3 / 2"
                    onClick={() => props.changeLense('Relative Hunger')}
                >{props.data.scarcity == "fies" ? "Prevalence of Food Insecure people" : "Prevalence of Stunted Children"}</Button>       
                <Button 
                    style={{borderRadius: "15px"}}
                    on="Absolute Hunger"
                    current={props.data.lense}
                    primary="#c76306"
                    secondary="rgba(199, 99, 6, 0.02)"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 2 / 3 / 3"
                    onClick={() => props.changeLense('Absolute Hunger')}
                >{props.data.scarcity == "fies" ? "Absolute number of food insecure people" : "Absolute Number of Stunted Children"}</Button>
                {/* <Button 
                    style={{borderRadius: "15px"}}
                    on='SDG Tracks'
                    current={props.data.lense}
                    primary="#c76306"
                    secondary="rgba(199, 99, 6, 0.02)"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 3 / 3 / 4"
                    onClick={() => props.changeLense('SDG Tracks')}
                >SDG Tracks</Button> */}
            </TripleDiv>
            <div />
        </BottomMenuDiv>
    )
}

export default BottomMenu