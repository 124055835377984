import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Twitter} from './assets/img/stroke-1.svg'
import { ReactComponent as ThumbsUp} from './assets/img/group-7.svg'
import { ReactComponent as LinkedIn} from './assets/img/group-6.svg'
import { ReactComponent as Web} from './assets/img/web-copy-2.svg'
import Ifad from './assets/img/ifad_logo.png'
import AreaIso from './data/AreaIso.json'
import { ReactComponent as Logo} from './assets/img/logoNoText.svg'
import { ReactComponent as Clock } from './assets/img/BigClock.svg'
import { ReactComponent as StickFigure } from './assets/img/BigStickFigure.svg'

const ConstP = styled.p`
    margin: 0;
    font-weight: 200;
    font-size: 1em;
    justify-self: start;
    color: white;
    @media (max-width: 1000px) {
        font-size: 1em;
    }
    @media (max-width: 768px) {
        font-size: .75em;
    }
`

const OutOf = styled(ConstP)`
    text-align: left;
    color: #c76306;
`

const PerDiv = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
    @media (max-width: 700px) {
        font-size: 12px;
    }
`  

const StyledClock = styled(Clock)`
    width: 40px;
    @media (max-width: 700px) {
        width: 25px;
    }
    @media (max-height: 550px) {
        width: 25px;
    }
`

const StyledStickFigure = styled(StickFigure)`
    @media (max-width: 700px) {
        width: 15px;
    }
`

const BottomFooter = styled.div`
    padding: 100px;
    background-color: #ebaa00;
    display: ${x => x.visible ? "grid" : "none"};
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 1150px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 768px) {
        padding: 25px 0px;
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr 1fr;
        display: ${x => x.visible ? "grid" : "none"};
    }
`

const InfoButton = styled.div`
    grid-column-start: 6;
    grid-column-end: 7;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    @media (max-width: 786px) {
        grid-area: 1 / 6 / 3 / 7;
        margin-right: 20px;
        height: 35px;
        width: 35px;
        font-size: 1.25em;
    }
`

const SocialButton = styled(InfoButton)`
    width: 40px;
    height: 40px;
    box-shadow: none;
    justify-self: start;
    align-self: center;
`

const ContentDiv = styled.div`
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-self: center;
    align-self: center;
    height: 100%;
    @media (max-width: 768px) {
        justify-self: start;
        padding-left: 50px;
    }
`

const SocialDiv = styled(ContentDiv)`
    padding: 80px 0px;
    @media (max-width: 768px) {
        padding: 50px 0;
        padding-left: 50px;
    }
`

const TitleDiv = styled(ContentDiv)`
    grid-template-rows: 200px 50px 75px;
    grid-template-columns: 15px 1fr;
`

const IfadDiv = styled(ContentDiv)`
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr;
    border-left: 1px solid white;
    padding: 80px 0px;
    padding-left: 20%;
    @media (max-width: 768px) {
        border-left: none;
        padding: 0;
        padding-left: 50px;

    }
`

const IfadLogo = styled.img`
    width: 100%;
    height: auto;
    align-self: start;
    @media (max-width: 768px) {
        width: 80%;
    }
`

const WorldHungerClock = styled.p`
    font-size: 3.5em;
    font-weight: 1000;
    text-align: left;
    color: white;
    margin: 0px;
    height: 100%;
    grid-area: 1 / 2 / 2 / 3;
    @media (max-width: 768px) {
        width: 50%;
        font-size: 3em;
    }
`

const CounterDiv = styled.div`
    display: grid;
    grid-template-rows: 1fr 2.5fr   ;
    margin: 0px 10px;
    height: 100%;
`

const StyledLogo = styled(Logo)`
    width: 75px;
    height: auto;
`

const SdgTrack = ["MEETS SDG", "ON TRACK", "OFF TRACK", "DESCENDING"]

function Footer(props) {


    const percentage = () => {
    
        const absolute = props.calculateTotal(props.data.scarcity, props.data.viewSelected, props.data.view, props.data.hungerData, props.data.year)
        
        let population
        if (props.data.viewSelected.includes("All")) {
            population = Object.values(props.data.hungerData[props.data.year]).reduce((a, c) => {return a.concat(Object.values(c))}, [])
            population = props.data.scarcity === 'fies' ? population.map(x => x['population']) : population.map(x => x['u5pop'])
            population = population.reduce((a, c) => a + c, 0)
        } else {
            if (props.data.hungerData[props.data.year][AreaIso[props.data.viewSelected]] == null) {
                return "NA"
            }
            population = props.data.scarcity === 'fies' ? Object.values(props.data.hungerData[props.data.year][AreaIso[props.data.viewSelected]])
            .map(x => x['population']).reduce((a, c) => a + c, 0) 
            : Object.values(props.data.hungerData[props.data.year][AreaIso[props.data.viewSelected]])
            .map(x => x['u5pop']).reduce((a, c) => a + c, 0)
        }
        return ((absolute / population * 100).toFixed(2))
    }

    const escapeRate = () => {
        let res
        let curYear = props.data.year === '2030' ? '2025' : props.data.year
        let nextYear = props.data.year === '2020' ? '2025' : '2030'
        let totalNow = props.calculateTotal(props.data.scarcity, props.data.viewSelected, props.data.view, props.bothData, curYear)
        let totalNext = props.calculateTotal(props.data.scarcity, props.data.viewSelected, props.data.view, props.bothData, nextYear)
        let diff = totalNext - totalNow
        let sec = 5 * 365 * 24 * 60 * 60
        let per = diff / sec
        if (diff === 0) {
            return `NA`
        }
        if (Math.abs(per) > 1) {
            return `${per.toFixed(1)} people per sec`
        }
        if (Math.abs(per) < 1) {
            let min = sec / 60
            per = diff / min
            res = `${per.toFixed(1)} people per min`
        } if (Math.abs(per) < 1) {
            let hours = (sec / 60) / 60
            per = diff / hours
            res = `${per.toFixed(1)} people per hour`
        } if (Math.abs(per) < 1) {
            let days = sec / 60 / 60 / 24
            per = diff / days
            res = `${per.toFixed(1)} people per day`
        } if (Math.abs(per) < 1) {
            let weeks = sec / 60 / 60 / 24 / 7
            per = diff / weeks
            res = `${per.toFixed(1)} people per week`
        } if (Math.abs(per) < 1) {
            let months = 5 * 12
            per = diff / months
            res = `${per.toFixed(1)} people per month`
        } 
        return res
    }

    const bottomLabel = (temp) => {
        if (temp.includes('-')) {
            return props.data.scarcity === "fies" ? "escape food insecurity" : "escape stunting"
        } else {
            return props.data.scarcity === "fies" ? "becomes food insecure" : "becomes stunted"
        }
    }

    const entersOrEscapes = () => {
        let escRate = escapeRate()
        let label = bottomLabel(escRate)
        escRate = escRate.includes('-') ? escRate.substring(1, escRate.length) : escRate
        return (
            <PerDiv>
                <StyledClock />
                <CounterDiv>
                    <p style={{fontSize: "12px", color: "#c76306", margin: "0", alignSelf: "start", fontWeight: "normal"}}>rate:</p>
                    <p style={{margin: "0",alignSelf: "start"}}>{escRate}</p>
                </CounterDiv>
                <p style={{fontSize: "14px", color: "#c76306", paddingTop: "4px"}}>{label}</p>
                {/* <StyledStickFigure />
                <div style={{display: 'flex', alignItems:'center'}}>
                    <p style={{maxHeight: "15px"}}>&ensp; 1 person &ensp;</p>
                </div>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <p style={{fontSize: "12px", color: "#c76306", fontWeight: "normal", paddingTop: "3px"}}>{label}</p>
                </div> */}
            </PerDiv>
        )
    }

    const absoluteHungry = () => {
        let viewS = props.data.viewSelected === 'All Countries' ? "THE WORLD" : props.data.viewSelected.toUpperCase()
        let res = props.data.scarcity === 'fies' ? `OF ${viewS} IS FOOD INSECURE`:
            `OF CHILDREN IN ${viewS} ARE STUNTED`
        let scarcity = props.data.scarcity === 'fies' ? 'population' : 'u5pop'
        let population = props.calculateTotal(scarcity, props.data.viewSelected, props.data.view, props.data.hungerData, props.data.year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return (
            <div style={{paddingRight: "5px", display: 'grid', gridTemplateRows: '1fr 1fr', borderRight: "1px solid white", justifyContent: "start"}}>
                <ConstP>{res}</ConstP>
                <OutOf>{props.data.total} out of {population}</OutOf>
            </ div>
        )
    }
    return (
        <BottomFooter visible={props.visible}>
            <TitleDiv>
                <WorldHungerClock>WORLD HUNGER CLOCK</WorldHungerClock>
                <p style={{color:"white", fontSize: "14px", gridArea: "2 / 2 / 3 / 3"}}>by</p>
                <div style={{display: "flex", alignItems: "center", justifyContent: 'flex-start', gridArea: '3 / 1 / 4 / 3'}}><a href="https://worlddata.io"><StyledLogo /></a><a href="https://worlddata.io" style={{textDecoration: "none", color: 'white', fontSize: '1.5em'}}>WORLD DATA LAB</a></div>
            </TitleDiv>
            <SocialDiv>
                <p style={{margin: "0", color:"white", fontSize:"1.25em", fontWeight:"bold", gridArea:"1 / 1 / 2 / 4", alignSelf: "center"}}>CONTACT US</p>
                <p style={{margin: "0", color:"white", gridArea:"2 / 1 / 3 / 4", alignSelf: "center"}}>hello@worlddata.io</p>
                <SocialButton
                    style={{gridRowStart:"3", gridRowEnd:"4", gridColumnStart:"1", gridColumnEnd:"2"}}>
                        <a href="https://twitter.com/worlddatalab"><Twitter /></ a>
                </SocialButton> 
                <SocialButton
                    style={{gridRowStart:"3", gridRowEnd:"4", gridColumnStart:"2", gridColumnEnd:"3"}}>
                        <a href="https://www.facebook.com/worlddatalab"><ThumbsUp /></ a>
                </SocialButton> 
                <SocialButton
                    style={{gridRowStart:"3", gridRowEnd:"4", gridColumnStart:"3", gridColumnEnd:"4"}}>
                        <a href="https://www.linkedin.com/company/world-data-lab/"><LinkedIn /></ a>
                </SocialButton> 
                <SocialButton
                    style={{gridRowStart:"3", gridRowEnd:"4", gridColumnStart:"4", gridColumnEnd:"5"}}>
                        <a href="https://worlddata.io"><Web /></ a>
                </SocialButton>
            </SocialDiv>
            <IfadDiv>
                <p style={{alignSelf: "end", color: "white"}}>supported by:</p>
                <IfadLogo src={Ifad} alt="Ifad" />
            </IfadDiv>
        </BottomFooter>
    )
}

export default Footer