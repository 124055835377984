import React from 'react'
import styled from 'styled-components'

/**
 * This is the button template for all rounded buttons
 */

const StyledButton = styled.button`
    background-color: ${props => props.color};
    color: ${props => props.textColor};
    grid-area: ${props => props.gridArea};
    border: 1px solid ${props => props.border};
    font-weight: ${props => props.weight};
    font-size: 14px;
    font-family: Work Sans;
    border-radius: 25px;
    &:hover {
        cursor: pointer;
    }
    @media (max-height: 500px) {
        font-size: 11px;
    }
    @media (max-width: 950px) {
        font-size: 11px;
    }
`

function Button(props) {
    let primary = props.on.includes(props.current)
    
    return (
        <StyledButton 
        width={props.width}  
        color={primary ? props.primary : props.secondary}
        textColor={primary ? props.primCol : props.secCol}
        weight = {primary ? "600" : "400"}
        gridArea={props.gridArea}
        border={props.primary}
        onClick={props.onClick}
        >
            {props.children}
        </StyledButton>
    )
}

export default Button