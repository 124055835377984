import styled, { css } from 'styled-components'

export const MobileDropDownDiv = styled.div`
    width: 80%;
    height: 70%;
    justify-self: center;
    align-self: center;
    display: flex;
`

export const Current = styled.p`
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 25px;
    padding-left: 20px;
    line-height: 7vh;
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: medium;
    font-family: Work Sans;
    cursor: pointer;
    border: none;
`

export const DropDown = css`
    width: 100%;
    max-height: 250px;
    position: absolute;
    top: ${props => props.top};
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding-bottom: 10px;
    overflow: scroll;
    cursor: pointer;
`

export const DropDownItem = css`
    font-weight: ${props => props.weight};
    font-family: Work Sans;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0px 25px 0px 15px;
    color: black;
    text-decoration: none;
    background-color: white;
    border-bottom: 1px solid #979797;
    hover: pointer;
`