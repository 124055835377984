import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Clock } from './assets/img/BigClock.svg'
import AreaIso from './data/AreaIso.json'


const MainDiv = styled.div`
    height: 10vh;
    background-color: black;
    display: grid;
    grid-template-columns: 1fr 0.6fr 2.4fr 4fr;
    align-items: center;
    grid-gap: 10px;
    @media (max-width: 811px) {
        grid-template-columns: 2px 0.75fr 3.25fr 3fr;
    }
`

const TotalP = styled.p`
    font-size: 2em;
    font-weight: 1000;
    margin: 0px;
    text-align: start;
    color: white;
    @media (max-width: 1300px) {
        font-size: 1.7em;
    }
    @media (max-width: 1000px) {
        font-size: 1.5em;
    }
    @media (max-width: 768px) {
        font-size: .9em;
    }
`

const ConstP = styled.p`
    margin: 0;
    font-weight: 200;
    font-size: 1em;
    justify-self: start;
    color: white;
    @media (max-width: 1000px) {
        font-size: 13px;
    }
    @media (max-width: 768px) {
        font-size: 11px;
    }
`

const OutOf = styled(ConstP)`
    text-align: left;
    color: #c76306;
`

const CounterDiv = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 0px 10px;
    height: 100%;
    @media (max-width: 1000px) {
        font-size: 13px;
    }
    @media (max-width: 768px) {
        margin: 0;
        grid-area: 1 / 1 / 2 / 2;
        font-size: 11px;
    }
`
const PerDiv = styled.div`
    display: flex;
    align-items: flex-end;
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
    @media (max-width: 768px) {
        margin-left: 0; 
        display: grid;
        grid-template-rows: 1fr 1fr;
    }
`  

const StyledClock = styled(Clock)`
    width: 40px;
    @media (max-width: 1000px) {
        width: 25px;
        height: 25px;
    }
    @media (max-width: 768px) {
        display: none;
    }
    @media (max-height: 550px) {
        display: none;
    }
`

const Label = styled.p`
    font-size: 13px;
    color: #c76306;
    margin: 0;
    @media (max-width: 768px) {
        font-size: 11px;
        padding-top: 0px;
        align-self: start;
    }
`



function TopFooter(props) {

    const percentage = () => {
    
        const absolute = props.calculateTotal(props.data.scarcity, props.data.viewSelected, props.data.view, props.data.hungerData, props.data.year)
        
        let population
        if (props.data.viewSelected.includes("All")) {
            population = Object.values(props.data.hungerData[props.data.year]).reduce((a, c) => {return a.concat(Object.values(c))}, [])
            population = props.data.scarcity === 'fies' ? population.map(x => x['population']) : population.map(x => x['u5pop'])
            population = population.reduce((a, c) => a + c, 0)
        } else {
            if (props.data.hungerData[props.data.year][AreaIso[props.data.viewSelected]] == null) {
                return "NA"
            }
            population = props.data.scarcity === 'fies' ? Object.values(props.data.hungerData[props.data.year][AreaIso[props.data.viewSelected]])
            .map(x => x['population']).reduce((a, c) => a + c, 0) 
            : Object.values(props.data.hungerData[props.data.year][AreaIso[props.data.viewSelected]])
            .map(x => x['u5pop']).reduce((a, c) => a + c, 0)
        }
        return ((absolute / population * 100).toFixed(2))
    }

    const escapeRate = () => {
        let res
        let curYear = props.data.year === '2030' ? '2025' : props.data.year
        let nextYear = props.data.year === '2020' ? '2025' : '2030'
        let totalNow = props.calculateTotal(props.data.scarcity, props.data.viewSelected, props.data.view, props.bothData, curYear)
        let totalNext = props.calculateTotal(props.data.scarcity, props.data.viewSelected, props.data.view, props.bothData, nextYear)
        let diff = totalNext - totalNow
        let sec = 5 * 365 * 24 * 60 * 60
        let per = diff / sec
        if (diff === 0) {
            return `NA`
        }
        if (Math.abs(per) > 1) {
            return `${per.toFixed(1)} people per sec`
        }
        if (Math.abs(per) < 1) {
            let min = sec / 60
            per = diff / min
            res = `${per.toFixed(1)} people per min`
        } if (Math.abs(per) < 1) {
            let hours = (sec / 60) / 60
            per = diff / hours
            res = `${per.toFixed(1)} people per hour`
        } if (Math.abs(per) < 1) {
            let days = sec / 60 / 60 / 24
            per = diff / days
            res = `${per.toFixed(1)} people per day`
        } if (Math.abs(per) < 1) {
            let weeks = sec / 60 / 60 / 24 / 7
            per = diff / weeks
            res = `${per.toFixed(1)} people per week`
        } if (Math.abs(per) < 1) {
            let months = 5 * 12
            per = diff / months
            res = `${per.toFixed(1)} people per month`
        } 
        return res
    }

    const bottomLabel = (temp) => {
        if (temp == null) {
            return "NA"
        }
        if (temp.includes('-')) {
            return props.data.scarcity === "fies" ? "escape food insecurity" : "escape stunting"
        } else {
            return props.data.scarcity === "fies" ? "becomes food insecure" : "becomes stunted"
        }
    }

    const entersOrEscapes = () => {
        let escRate = escapeRate()
        let label = bottomLabel(escRate)
        escRate = escRate != null ? (escRate.includes('-') ? escRate.substring(1, escRate.length) : escRate) : ""
        return (
            <PerDiv>
                <StyledClock />
                <CounterDiv>
                    <p style={{fontSize: "12px", color: "#c76306", margin: "0", alignSelf: "start", fontWeight: "normal"}}>rate:</p>
                    <p style={{margin: "0",alignSelf: "end"}}>{escRate}</p>
                </CounterDiv>
                <Label>{label}</Label>
                {/* <StyledStickFigure />
                <div style={{display: 'flex', alignItems:'center'}}>
                    <p style={{maxHeight: "15px"}}>&ensp; 1 person &ensp;</p>
                </div>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <p style={{fontSize: "12px", color: "#c76306", fontWeight: "normal", paddingTop: "3px"}}>{label}</p>
                </div> */}
            </PerDiv>
        )
    }

    const absoluteHungry = () => {
        let viewS = props.data.viewSelected === 'All Countries' ? "THE WORLD" : props.data.viewSelected.toUpperCase()
        let res = props.data.scarcity === 'fies' ? `OF ${viewS} IS FOOD INSECURE`:
            `OF CHILDREN IN ${viewS} ARE STUNTED`
        let scarcity = props.data.scarcity === 'fies' ? 'population' : 'u5pop'
        let population = props.calculateTotal(scarcity, props.data.viewSelected, props.data.view, props.data.hungerData, props.data.year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return (
            <div style={{paddingRight: "5px", display: 'grid', gridTemplateRows: '1fr 1fr', borderRight: "1px solid white", justifyContent: "start"}}>
                <ConstP>{res}</ConstP>
                <OutOf>{props.data.total} out of {population}</OutOf>
            </ div>
        )
    }

    return ( 
        <MainDiv>
            <div />
            <div style={{display: "flex", alignItems: "center"}}><TotalP>{percentage()}%</TotalP></ div>
            {absoluteHungry()}
            {/* <div style={{display: 'grid', gridTemplateRows: '60% 40%', justifyItems: "center"}}>
                <p style={{margin: "0", fontWeight: "bold", fontSize: "18px", color:"red"}}>OFF-TRACK</p>
                <p style={{margin: "0", color: "white"}}>SDG 2.1</p>
            </div> */}
            {entersOrEscapes()}
        </MainDiv>
    )
}

export default TopFooter