import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './components/Button'
import MobileDropDown from './components/MobileDropDown'
import MobileSearchBar from './components/MobileSearchBar'
import * as Constants from './constants'
import {ReactComponent as Logo} from './assets/img/logoNoText.svg'
import { ReactComponent as Close } from './assets/img/Close.svg'
import { ReactComponent as FilterIcon } from './assets/img/lenses_icon.svg'
import { ReactComponent as MarkerIcon } from './assets/img/marker_icon.svg'


/**
 * This is the menu for selecting the filters
 */

const Overlay = styled.div`
    height: 100%;
    width: ${x => x.open ? "100%" : "0%"};
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    display: grid;
    grid-template-rows: 10vh 20vh 5vh 10vh 10vh 5vh 10vh 10vh 10vh;
    transition: 0.5s;
`
const LenseOverlay = styled(Overlay)`
    row-gap: 25px;
    grid-template-rows: 10vh 25vh 10vh 10vh 10vh 35vh;

`

const OverlayDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-self: center;
    width: 80%;
`


const MobileBottomMenuDiv = styled.div`
    height: 10vh;
    grid-template-columns: 1fr 1fr 1fr;
    display: none;
    @media (max-width: 811px) {
        display: grid;
    }
`

const MobileDiv = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const MobileButton = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    font-family: Work Sans;
    background-color: ${props => props.color};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`

const BottomButton = styled.button`
    color: ${props => props.color};
    background-color: ${props => props.background};
    width: 80%;
    height: 70%;
    justify-self: center;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    border: none;
    font-size: large;
    font-weight: 600;
`

function MobileMenu(props) {

    const [openFilter, setOpenFilter] = useState(false)
    const [openLense, setOpenLense] = useState(false)
    const [data, changeData] = useState(Constants.COUNTRYLIST)

    const handleUrb = (event) => {
        console.log(event)
        if (!(event === props.data.urb)) {
            if (props.data.urb === 'both') {
                if (event === 'rural') {
                    props.changeUrb('urban')
                } else {
                    props.changeUrb('rural')
                }
            } else {
                props.changeUrb('both')
            }
        }
    }

    const viewData = (event) => {
        switch(event) {
            case 'countries':
                changeData(Constants.COUNTRYLIST)
                break
            case 'continents':
                changeData(Constants.CONTINENTS)
                break
            case 'low income countries':
                changeData(Constants.LOWINCOMECOUNTRIES)
                break
            case 'fragile states':
                changeData(Constants.FRAGILESTATES)
                break
            default:
                break
        }
    }


    return (
        <MobileBottomMenuDiv>
            <Overlay open={openFilter}>
                <OverlayDiv>
                    {/* <a href="https://worlddata.io"
                    ><Logo style={{width: "75px", height: "75px"}}></Logo></a>  */}
                    {/* <button style={{
                        justifySelf: 'end',
                        fontSize: '2em',
                        color: "white",
                        textDecoration: "none",
                        cursor: "pointer",
                        background: "none",
                        border: "none"
                        }}
                        onClick={() => setOpenFilter(false)}><Close /></button> */}
                    <Close style={{width: '25px', height: '25px', justifySelf: 'start', alignSelf: 'center', cursor: 'pointer'}} onClick={() => setOpenFilter(false)} />
                </OverlayDiv>
                <OverlayDiv>
                <Button 
                    style={{fontSize: "16px"}}
                    on={"fies"}
                    current={props.data.scarcity}
                    primary="#ebaa00"
                    secondary="#fdf6e5"
                    primCol="black"
                    secCol="black"
                    gridArea="1 / 1 / 2 / 2"
                    onClick={() => props.changeScarcity('fies')}
                >food insecurity</Button>
                <Button 
                    style={{fontSize: "16px"}}
                    on={"stunting"}
                    current={props.data.scarcity}
                    primary="#ebaa00"
                    secondary="#fdf6e5  "
                    primCol="black"
                    secCol="black"
                    gridArea="1 / 2 / 2 / 3"
                    onClick={() => props.changeScarcity('stunting')}
                >stunting</Button>
                <Button 
                    style={{fontSize: "16px"}}
                    on={["urban", "both"]}
                    current={props.data.urb}
                    primary="#c76306"
                    secondary="#fdf6e5"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 1 / 3 / 2"
                    onClick={() => handleUrb('urban')}
                >urban</Button>
                <Button 
                    style={{fontSize: "16px"}}
                    on={["rural", "both"]}
                    current={props.data.urb}
                    primary="#c76306"
                    secondary="#fdf6e5"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 2 / 3 / 3"
                    onClick={() => handleUrb('rural')}
                >rural</Button>
                </OverlayDiv>
                <p style={{color: "white", width: "80%", justifySelf:"center"}}>CHANGE VIEW</p>
                <MobileDropDown 
                    data={Constants.VIEWOPTIONS}
                    selected={props.data.view}
                    onClick={(e) => {
                        props.changeView(e)
                        viewData(e)
                    }}
                    top={45}/>
                <MobileSearchBar 
                    data={data}
                    viewSelected={props.data.viewSelected}
                    changeViewSelected={props.changeViewSelected}
                />
                <p style={{color: "white", width: "80%", justifySelf:"center"}}>CHANGE YEAR</p>
                <MobileDropDown 
                    data={["2020", "2025", "2030"]}
                    selected={props.data.year}
                    onClick={(e) => props.changeYear(e)}
                    top={70}
                />
                <BottomButton color="black" background="#ebaa00">Explore More</BottomButton>
                <BottomButton onClick={props.reset} color="white" background="#c76306">Reset</BottomButton>

            </Overlay>
            <LenseOverlay open={openLense}>
                <OverlayDiv>
                    <a href="https://worlddata.io"
                    ><Logo style={{width: "75px", height: "75px"}}></Logo></a> 
                    <button style={{
                        justifySelf: 'end',
                        fontSize: '2em',
                        color: "white",
                        textDecoration: "none",
                        cursor: "pointer",
                        background: "none",
                        border: "none"
                        }}
                        onClick={() => setOpenLense(false)}>&#10006;</button>
                </OverlayDiv>
                <div />
                <OverlayDiv>
                    <Button 
                        style={{borderRadius: "30px"}}
                        on="Relative Hunger"
                        current={props.data.lense}
                        primary="#c76306"
                        secondary="#fdf6e5"
                        primCol="white"
                        secCol="black"
                        gridArea="1 / 1 / 3 / 3"
                        onClick={() => props.changeLense('Relative Hunger')}
                    >Relative Hunger</Button>
                </OverlayDiv>
                <OverlayDiv>
                    <Button 
                        style={{borderRadius: "30px"}}
                        on="Absolute Hunger"
                        current={props.data.lense}
                        primary="#c76306"
                        secondary="#fdf6e5"
                        primCol="white"
                        secCol="black"
                        gridArea="1 / 1 / 3 / 3"
                        onClick={() => props.changeLense('Absolute Hunger')}
                    >Absolute Hunger</Button>
                </OverlayDiv>
            </LenseOverlay>
            <MobileDiv onClick={() => setOpenFilter(true)}><MobileButton color="#ebaa00"><FilterIcon style={{width: "20px", height: "auto"}} /></MobileButton><p>Filters</p></MobileDiv>
            <MobileDiv onClick={() => {
                        if (props.data.year === '2020') {
                            if (props.data.live === 'on') {
                                props.changeLive('off')
                            } else {
                                props.changeLive('on')
                            }
                        }
                    }}><MobileButton color="#fc1e1e" ></MobileButton><p>Live</p></MobileDiv>
            <MobileDiv onClick={() => {setOpenLense(true)}}><MobileButton color="#c76306"><MarkerIcon style={{width: "30px", height: "auto"}} /></MobileButton><p>Lenses</p></MobileDiv>
        </MobileBottomMenuDiv>
    )

}

export default MobileMenu