import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo} from './../assets/img/logoNoText.svg'
import { ReactComponent as Close } from './../assets/img/Close.svg'

/**
 * This is the menu overlay and information
 */

/**
 * The black overlay which comes in from the left
 */
const Overlay = styled.div`
    height: 100%;
    width: ${x => x.open ? "100%" : "0%"};
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px 400px 50px;
    transition: 0.5s;
    @media (max-width: 768px) {
        grid-template-columns: none;
        grid-template-rows: 10vh 1fr 1fr auto;
    }
`
/**
 * Contains the logo and the close button
 */
const Header = styled.div`
    padding: 0 150px;
    display: grid;
    grid-template-columns: 75px 225px 1fr;
    grid-area: 1 / 1 / 2 / 3;
    align-items: center;
    @media (max-width: 768px) {
        padding: 0;
        display: flex;
        justify-content: center;
    }
`
/**
 * This is a general section. There are different sections for the margins
 * and the border 
 */
const Section = styled.div`
    width: 80%;
    display: grid;
    grid-template-rows 50px auto auto 1fr;
    grid-row-start: 2;
    grid-row-end: 3;
    @media (max-width: 768px) {
        width: 90%;
    }
`
/**
 * Left section with a larger margin and a right border
 */
const LeftSection = styled(Section)`
    margin-left: 150px;
    border-right: 2px solid white;
    grid-column-start: 1;
    grid-column-end: 2;
    @media (max-width: 768px) {
        margin-left: 20px;
        border-right: none;
    }
`

/**
 * Right section with no border, and it goes below the left section when the mobile version 
 */
const RightSection = styled(Section)`
    margin-left: 50px;
    grid-column-start: 2;
    grid-column-end: 3;
    @media (max-width: 768px) {
        margin-top: 30px;
        margin-left: 20px;
        grid-area: 3 / 1 / 4 / 2;
    }
`
/**
 * Contains the social links
 */
const Footer = styled.div`
    margin-left: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
    grid-row-start: 3;
    grid-row-end: 4;
    @media (max-width: 768px) {
        margin-left: 20px;
        grid-row-start: 4;
        grid-row-end: 5;
    }
`

const Title = styled.p`
    font-family: Berlingske Serif;
    color: white;
    font-size: 2.5em;
    max-width: 80%;
    margin: 0;
`

const Heading = styled.p`
    color: white;
    font-size: 1.2em;
    font-weight: 200;
`

const P = styled.p`
    color: white;
    max-width: 95%;
`

const RedLink = styled.a`
    color: white;
    font-size: 1.1em;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    cursor: pointer;
    background: none;
    border: none;
`

const WhiteLink = styled.a`
    max-width: 100%;
    color: #ebaa00;
    font-size: 1.3em;
    font-weight: 200;
    text-decoration: none;
    margin-right: 25px;
    cursor: pointer;
    background: none;
    border: none;
`

const WhiteLink2 = styled.a`
    max-width: 100%;
    color: white;
    font-size: 1.3em;
    font-weight: 200;
    text-decoration: none;
    margin-right: 25px;
    cursor: pointer;
    background: none;
    border: none;
`

function Menu(props) {
    return (
        <Overlay open={props.data.menuOpen}>
            <Header>
                <a href="https://worlddata.io"
                ><Logo style={{width: "75px", height: "75px"}}></Logo></a> 
                <WhiteLink href="https://worddata.io">WORLD DATA LAB</WhiteLink>
                {/* <button style={{
                    justifySelf: 'end',
                    fontSize: '2em',
                    fontWeight: '300',
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                    background: "none",
                    border: "none"
                    }}
                    onClick={() => props.setMenuOpen(false)}> */}
                        <Close style={{width: '25px', height: '25px', justifySelf: 'end', alignSelf: 'center', cursor: 'pointer'}} onClick={() => props.setMenuOpen(false)} />
                    {/* </button> */}
            </Header>
            <LeftSection>
                <Heading>ABOUT WDL</Heading>
                <Title>Make Everyone Count with Groundbreaking Data</Title>
                <P>World Data Lab is driven by an appetite for innovation. We push the boundaries of what is knowable and actionable. Granular economic and demographic forecasts—at any point in time—are our specialty.</P>
                <div><RedLink href="https://worlddata.io">Find Out More</RedLink></div>
                <div><WhiteLink2 href="/methodology" style={{textDecoration: "none"}}>METHODOLOGY</WhiteLink2><br /><WhiteLink2 href="https://worlddata.io/blog/">BLOG</WhiteLink2></div>
            </LeftSection>
            <RightSection>
                <Heading>GET INVOLVED</Heading>
                <Title>Help fund expansion of World Hunger Clock</Title>
                <P>Help us create the data necessary to end extreme hunger. We are interested in partnering with companies and organizations to create state-of-the-art hunger models and forecasts at a highly granular level.</P>
                <div><RedLink onClick={() => {props.setMenuOpen(false); props.setRequestData(true)}}>Get In Touch</RedLink></div>
            </RightSection>
            <Footer>
                <WhiteLink href="https://www.linkedin.com/company/world-data-lab/">LinkedIn</WhiteLink><WhiteLink href="https://twitter.com/worlddatalab">Twitter</WhiteLink><WhiteLink href="https://worlddata.io/index.php/press-media">Press</WhiteLink>
                <WhiteLink href="https://www.facebook.com/worlddatalab">FaceBook</WhiteLink><WhiteLink href="https://www.instagram.com/worlddatalab/?hl=en">Instagram</WhiteLink>
            </Footer>
        </Overlay>
    )
}

export default Menu