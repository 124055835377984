import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import logoImage from './assets/img/wdl-scaled.svg';
import laptop from './assets/img/laptop.svg';
import laptopExtended from './assets/img/laptop-extended.svg';
import mainLogo from './assets/img/main-logo.svg';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  font-family: 'Arial, sans-serif';
  background-color: #FFF7E9;
  overflow: hidden ;
  flex-grow: 0;

  @media (max-width: 1024px) {
  width: 100vw;
  padding: 1px !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  }
`;
const MainLogo = styled.div`
  position: absolute;
  margin-left: 2%; 
  margin-top: 2%; 
  transform: scale(0.9); 
`;
const LogoText = styled.span`
  margin-left: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  color: black; 
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2rem;
  box-sizing: border-box;
  margin: 20% 15% 80 5%;
  flex-grow: 1;   
  max-width: 50%; 
  margin-top: 5%;
  margin-left: 5%;
  overflow: hidden; 

  @media (max-width: 1024px) { 
    padding: 1rem;
    margin: 5%;
    min-width: 80%; 
    max-width: 90%;  
  }
`;
const LogoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  @media (max-width: 1024px) { 
    gap: 0rem;
  }
`;
const Logo = styled.img`
  transform: scale(0.8);
  height: 5rem;
  align-self: flex-start;

  @media (max-width: 1024px) {
    transform: scale(0.6);
    height: 5rem;
    align-self: flex-start;

  }
`;
const Header = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #c76207;

  @media (max-width: 1024px) {
    font-size: 1.3rem;

  }
`;
const ImageStyle = styled.img`
  display: block;
  margin-top: 7%;
  margin-right: 7%;

  @media (max-width: 1024px) {
    margin-right: 0%;
    margin-top: -3%;
    align-self: center;
    transform: scale(0.75);

  }
`;
const Text = styled.p`
  font-size: 1.25rem;
  text-align: left;
  margin-bottom: 0.25rem;

  @media (max-width: 1024px) {
    margin-top: 0rem;
    font-size: 1.95vh;
    margin-bottom: 2vh;

  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  margin-bottom: 0.5rem;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1%;
    bottom: 10%;
  }
`;
const Button = styled.a`
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #F4A261;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: #c76207;
  }
`;
const Footer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 0.2rem solid #ccc;
  padding: 0.5rem 1rem;
  text-align: right;
  z-index: 1;

  @media (max-width: 1024px) {
    padding: 0.5rem 0.5rem 0.7rem;
    font-size: 0.8rem;
  }
`;

const Maintenance = () => {
  const [currentImage, setCurrentImage] = useState(laptop);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
      setCurrentImage(window.innerWidth < 1024 ? laptop : laptopExtended);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [hoverStatus, setHoverStatus] = useState({ contactUs: false, aboutUs: false });

  return (
    <Container>
      {!isMobile &&
      (<MainLogo>
        <img src={mainLogo} alt="World Data Lab Logo"/> 
        <LogoText>World Data Lab</LogoText>
      </MainLogo>
      )}
      <ContentBox>
        <LogoHeaderContainer>
          <Logo src={logoImage} alt="World Data Lab Logo" />
          <Header>World Hunger Clock Update</Header>
        </LogoHeaderContainer>
        {isMobile && <ImageStyle alt="Laptop screen" src={currentImage} />}
        <Text>
          Please be informed that the World Hunger Clock is out of date and is in the process of being updated. We apologize for any inconvenience this may cause and appreciate your patience as we work to provide you with the most accurate and up-to-date information.
        </Text>
        <Text>
          Additionally, we are actively seeking partnerships with agencies interested in funding the update of our data. Your support will help us enhance the quality and comprehensiveness of the tracking of hunger that we provide. For inquiries or to express interest in funding opportunities, please contact us at hello@worlddata.io.
        </Text>
        <ButtonContainer>
          <Button href="https://worlddata.io/"
                  onMouseEnter={() => setHoverStatus({ ...hoverStatus, contactUs: true })}
                  onMouseLeave={() => setHoverStatus({ ...hoverStatus, contactUs: false })}>
            About Us
          </Button>
          <Button href="https://worlddata.io/our-blog/"
                  onMouseEnter={() => setHoverStatus({ ...hoverStatus, aboutUs: true })}
                  onMouseLeave={() => setHoverStatus({ ...hoverStatus, aboutUs: false })}>
            WDL Blogs
          </Button>
        </ButtonContainer>
      </ContentBox>
      {!isMobile && <ImageStyle alt="Laptop screen" src={currentImage} />}
      <Footer>
        Copy rights to World Data Lab 2024
      </Footer>


    </Container>
  );
};

export default Maintenance;
