import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DropDown, DropDownItem } from './../assets/css/commonStyles.js'


/**
 * This is the search bar for countries
 */

const Wrapper  = styled.div`
    grid-area: 2 / 1 / 3 / 3;
    border-bottom: 1px solid #979797;
    margin-bottom: 3px; 
    display: flex;
    &:: after {
        content: "v";
        position: absolute;
        right: 20px;
        bottom: 13px;
        font-size: 10px; 
        font-weight: normal;
        @media (max-height: 700px) {
            bottom: 6px;
        }
    }
`

const Current = styled.input`
    align-self: center;
    width: 90%;
    border: none;
    font-size: medium;
    color: black;
    outline: none;
    font-size: 14px;
`

const Text = styled.p`
    grid-area: 1 / 1 / 2 / 3;
    font-size: 14px;
    padding: 0;
    margin: 0;
    align-self: center;
    color: #4a2401;
    @media (max-height: 540px) {
        display: none;
    }
`

const DropDownDiv = styled.div`${DropDown}`

const DropDownElement = styled.p`${DropDownItem}`

function SearchBar(props) {

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(props.data)
    const [tempView, changeTempView] = useState(props.viewSelected)

    //Changes the view of the selected country
    useEffect(() => {
        changeTempView(props.viewSelected)
    }, [props.viewSelected])

    /**
     * Change the text on the input field, and changes the viewSelected prop once the user 
     * has typed input matches a country name
     * @param {Typing Event} event 
     */
    const handleChange = (event) => {
        setOpen(true)
        changeTempView(event.target.value)
        let toLowerCase = event.target.value.toLowerCase();
        if (!props.data.includes(event.target.value)) {
            setData(props.data.filter(x => {
                return x.toLowerCase().includes(toLowerCase)
            }))
        } else {
            props.changeViewSelected(event.target.value)
            setData(props.data)
            setOpen(false)
        }
    }
    
    /**
     * Once the user clicks a selection, this methods updates the viewSelected State
     * @param {Click Event} event 
     */
    const onClick = (event) => {
        changeTempView(event)
        props.changeViewSelected(event)
        setOpen(false)
    }

    return (
        <props.div style={{borderRight: "1px solid #979797", paddingRight: "5%"}}>

            <Text>CHANGE COUNTRY</Text>        
            <Wrapper>
                <Current 
                    value={tempView}
                    onChange={handleChange} 
                    onClick={() => {
                            setOpen(!open)
                            setData(props.data)
                        }
                    }/>
            </Wrapper>
            {open && 
                <DropDownDiv top="8vh">
                    {data.map(x => 
                        <DropDownElement 
                            key={x}
                            weight={props.viewSelected === x ? 600 : 400}
                            onClick={() => onClick(x)}>
                            {x}
                        </DropDownElement>)}
                </DropDownDiv>
            }
        </props.div>
    )
}

export default SearchBar