import React, { useState } from 'react'
import styled from 'styled-components'
import DropDownMenu from './components/DropDownMenu'
import SearchBar from './components/SearchBar'
import Button from './components/Button'
import Burger from './components/Burger'
import * as Constants from './constants'
import { ReactComponent as Logo} from './assets/img/logo.svg'
import { ReactComponent as LogoNoText} from './assets/img/logoNoText.svg';

/**
 * This is the top menu
 */

const HeaderLayout = styled.div`
    height: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
`

const LogoDivMobile = styled.div`
    display: none;
    height: 10vh;
    @media (max-width: 768px) {
        display: grid;
        grid-area: 1 / 1 / 2 / 7;
        grid-template-columns: 75px 1fr 50px;
        grid-template-rows: 1fr 1fr;

    }
`

const LogoDiv = styled.div`
    justify-self: center;
    margin-left: 20px;
    @media (max-width: 768px) {
        display: none;
    }
`

const HungerClock = styled.p`
    font-family: Work Sans;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    grid-area: 1 / 2 / 2 / 4;
    align-self: end;
    justify-self: start;
    margin: 10px 0 0 0;
    @media (max-width: 768px) {
        font-size: 18px;
    }   
    @media (max-height: 440 px) {
        font-size: 16px;
    }
    @media (max-width: 320px) {
        font-size: 13px;
    }
`
const ByWDL = styled(HungerClock)`
    font-size: 18px;
    grid-area: 2 / 2 / 3 / 3;
    align-self: start;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 2px;
    margin: 0 0 10px 0;
    @media (max-width: 768px) {
        align-self: start;
        font-size: 14px;
    }
    @media (max-width: 400px) {
        font-size: 12px;
    }
    @media (max-height: 675px) {
        font-size: 20px;
        align-self: start;
    }
    @media (max-height: 675px) {
        font-size: 12px;
        align-self: start;
    }
    @media (max-height: 385px) {
        font-size: 12px;
    }
    @media (max-width: 320px) {
        font-size: 12px;
    }
`

const MenuDiv = styled.div`
    grid-area: 1 / 3 / 3 / 4;
    display: flex;
    align-items: center;
    background: white;
    justify-content: center;
`

const StyledLogo = styled(Logo)`
    width: auto;
    height: 8vh;
    @media (max-width: 400px) {
        height: 60px;
    }
`

const StyledLogoNoText = styled(LogoNoText)`
widht: 8vh;
height: 8vh;
@media (max-width: 768px) {
    grid-area: 1 / 1 / 3 / 2
}
`

const UrbDiv = styled.div`
    display: grid;
    grid-template-columns: 60% 40%;
    @media (max-width: 1150px) {
        font-size: 1em;
    }
`

const UrbWord = styled.p`
    margin: 0;
    font-family: Work Sans;
    color: white;
    justify-self: start;
`

const UrbNumber = styled.p`
    font-family: Work Sans;
    text-align: right;
    font-weight: normal;
    color: black;
    opacity: 0.5;
    margin: 0;
`

function Header(props) {
    
    const [data, changeData] = useState(Constants.COUNTRYLIST)

    const handleUrb = (event) => {
        if (!(event === props.data.urb)) {
            if (props.data.urb === 'both') {
                if (event === 'rural') {
                    props.changeUrb('urban')
                } else {
                    props.changeUrb('rural')
                }
            } else {
                props.changeUrb('both')
            }
        }
    }

    const viewData = (event) => {
        switch(event) {
            case 'countries':
                changeData(Constants.COUNTRYLIST)
                break
            case 'continents':
                changeData(Constants.CONTINENTS)
                break
            case 'low income countries':
                changeData(Constants.LOWINCOMECOUNTRIES)
                break
            case 'fragile states':
                changeData(Constants.FRAGILESTATES)
                break
            default:
                break
        }
    }

    const urbNumber = (urb) => {
        let billions = urb / 1000000000
        if (billions < 1) {
            let millions = urb / 1000000
            return `${millions.toFixed(1)}M`
        } else {
            return `${billions.toFixed(1)}B`
        }
    }

    return (
        <HeaderLayout>
            <LogoDivMobile>
                <div style={{gridArea: "1 / 1 / 3 / 2", alignSelf: "stretch", justifySelf: "stretch", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <a href="https://worlddata.io"><StyledLogoNoText></StyledLogoNoText></a>
                </div>
                <HungerClock>World Hunger Clock</HungerClock>
                <ByWDL>by WORLD DATA LAB</ByWDL>
                <MenuDiv>
                    <Burger menuOpen={props.data.menuOpen} setMenuOpen={props.setMenuOpen}></Burger>
                </MenuDiv>
            </LogoDivMobile>
            <LogoDiv>
                <a href="https://worlddata.io"><StyledLogo></StyledLogo></a>
            </LogoDiv>
            <DropDownMenu 
                div = {props.div}
                currentView = {props.data.view}
                changeCurrentView = {props.changeView}
                changeViewData = {viewData}
                data = {Constants.VIEWOPTIONS} />
            <SearchBar 
                div={props.div} 
                viewSelected = {props.data.viewSelected}
                changeViewSelected = {props.changeViewSelected}
                data = {data}/>
            <props.div style={{paddingRight: "5%", borderRight: "1px solid #979797"}}>
                <Button 
                    style={props.data.scarcity === 'fies' ? {borderRadius: '30px 30px 0 0'} : {borderRadius: '50px'}}
                    on={"fies"}
                    current={props.data.scarcity}
                    primary="#ebaa00"
                    secondary="rgba(235, 170, 0, 0.1)"
                    primCol="black"
                    secCol="black"
                    gridArea={`1 / 1 / ${props.data.scarcity === 'fies' ? 2 : 3} / 3`}
                    onClick={() => props.changeScarcity('fies')}
                >food insecurity</Button>
                <Button 
                    style={props.data.scarcity === 'fies' ? {} : {display: 'none'}}
                    on={["urban", "both"]}
                    current={props.data.urb}
                    primary="#c76306"
                    secondary="rgba(199, 99, 6, 0.15)"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 1 / 3 / 2"
                    onClick={() => handleUrb('urban')}
                    ><UrbDiv><UrbWord>urban</UrbWord><UrbNumber>{urbNumber(props.data.urban)}</UrbNumber></UrbDiv></Button>
                <Button 
                    style={props.data.scarcity === 'fies' ? {} : {display: 'none'}}
                    on={["rural", "both"]}
                    current={props.data.urb}
                    primary="#c76306"
                    secondary="rgba(199, 99, 6, 0.15)"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 2 / 3 / 3"
                    onClick={() => handleUrb('rural')}
                    ><UrbDiv><UrbWord>rural</UrbWord><UrbNumber>{urbNumber(props.data.rural)}</UrbNumber></UrbDiv></Button>
            </props.div>
            <props.div>
                <Button
                    style={props.data.scarcity === 'stunting' ? {borderRadius: '30px 30px 0 0'} : {borderRadius: '50px'}}
                    on={"stunting"}
                    current={props.data.scarcity}
                    primary="#ebaa00"
                    secondary="rgba(235, 170, 0, 0.1)"
                    primCol="black"
                    secCol="black"
                    gridArea={`1 / 1 / ${props.data.scarcity === 'stunting' ? '2' : '3'} / 3`}
                    onClick={() => props.changeScarcity('stunting')}>
                    stunting
                </Button>
                <Button 
                    style={props.data.scarcity === 'stunting' ? {} : {display: 'none'}}
                    on={["urban", "both"]}
                    current={props.data.urb}
                    primary="#c76306"
                    secondary="rgba(199, 99, 6, 0.15)"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 1 / 3 / 2"
                    onClick={() => handleUrb('urban')}
                ><UrbDiv><UrbWord>urban</UrbWord><UrbNumber>{urbNumber(props.data.urban)}</UrbNumber></UrbDiv></Button>
                <Button 
                    style={props.data.scarcity === 'stunting' ? {} : {display: 'none'}}
                    on={["rural", "both"]}
                    current={props.data.urb}
                    primary="#c76306"
                    secondary="rgba(199, 99, 6, 0.15)"
                    primCol="white"
                    secCol="black"
                    gridArea="2 / 2 / 3 / 3"
                    onClick={() => handleUrb('rural')}
                ><UrbDiv><UrbWord>rural</UrbWord><UrbNumber>{urbNumber(props.data.rural)}</UrbNumber></UrbDiv></Button>
            </props.div>
            <props.div>
                <p style={{
                    justifySelf: "end",
                    alignSelf: "center",
                    paddingRight: "20px",
                    fontWeight: "400",
                    fontSize: "16px",
                    gridColumnStart: 1,
                    gridColumnEnd: 2,
                    gridRowStart: 1,
                    gridRowEnd: 3
                }}>MENU</p>
                <Burger menuOpen={props.data.menuOpen} setMenuOpen={props.setMenuOpen}></Burger>
            </props.div>
        </HeaderLayout>
    )
}

export default Header