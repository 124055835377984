import React from 'react'
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Start from './Start'
import Header from './Header'
import HungerMap from './HungerMap'
import BottomMenu from './BottomMenu'
import Footer from './Footer'
import TopFooter from './TopFooter'
import Menu from './components/Menu'
import RequestData from'./components/RequestData'
import MobileMenu from './MobileMenu'
import Methodology from './Methodology'
import * as Constants from './constants'
import AreaIso from './data/AreaIso.json'
import IsoArea from './data/IsoArea.json'
import styled, { createGlobalStyle } from "styled-components";
import spinner from './assets/img/loadingIcon.gif';
import appConfig from './config/app';
import Maintenance from './Maintenance';

import { Link, Element } from "react-scroll";
import './assets/css/style.css'
import './assets/fonts/BerlingskeSerif/style.css'

/**
 * This is the overarching class
 */

const GlobalStyles = createGlobalStyle`
    body {
        font-family: 'Work Sans', sans-serif;
    }
`

const OptionDiv = styled.div`
    grid-template-rows: 50% 50%;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    align-self: center;
    display: grid;
    justify-self: center;
    position: relative;
    z-index: 401;
    height: 8vh;
    @media (max-width: 768px) {
        display: none;
    }
`

const MenuDiv = styled(OptionDiv)`
    grid-column-start: 4;
    grid-column-start: 5;
    display: none;
    @media (max-width: 768px) {
        display: grid;
        justify-items: end;
    }
`

let both 
let rural 
let urban

window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}
  
const calculateTotal = (scarcity, country, view, data, year) => {
    let t
    if (country.includes("All")) {
        switch (view) {
            case 'low income countries':
                t = Object.keys(data[year])
                .filter(x => Constants.LOWINCOMECOUNTRIES.includes(IsoArea[x]))
                .reduce((r, k) => {
                    return r.concat(Object.values(data[year][k]));
                }, [])
                break
            case 'fragile states':
                t = Object.keys(data[this.state.year])
                .filter(x => Constants.FRAGILESTATES.includes(IsoArea[x]))
                .reduce((r, k) => {
                    return r.concat(Object.values(data[year][k]));
                }, [])
                break
            default: 
                t = Object.keys(data[year])
                .reduce((r, k) => {
                    return r.concat(Object.values(data[year][k]));
                }, [])
        }   
    } 
    if (data[year][AreaIso[country]] != null) {
        t = Object.values(data[year][AreaIso[country]])
    }
    if (t != null) {
        t = t.map(x => x[scarcity])
        .filter(x => typeof(x) === 'number')
        .reduce((a, b) => a + b, 0)
    }
    else {
        t = "Data Unavailable"
    } 
    return t
}

class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            view: 'countries',
            viewSelected: 'All Countries',
            scarcity: 'fies',
            urb: 'both',
            year: '2020',
            live: 'on',
            lense: 'Relative Hunger',
            total: '',
            urban: 0,
            rural: 0,
            menuOpen: false,
            requestData: false,
            hungerData: null,
            start: true,
            footer: false,
            isMaintenanceMode: true,
        }

        let areaIsoKeys = Object.keys(AreaIso)
        console.log(areaIsoKeys.filter(x => !Constants.COUNTRYLIST.includes(x)))
        this.changeView = this.changeView.bind(this)
        this.changeViewSelected = this.changeViewSelected.bind(this)
        this.changeScarcity = this.changeScarcity.bind(this)
        this.changeUrb = this.changeUrb.bind(this)
        this.changeYear = this.changeYear.bind(this)
        this.changeLive = this.changeLive.bind(this)
        this.changeLense = this.changeLense.bind(this)
        this.setMenuOpen = this.setMenuOpen.bind(this)
        this.setRequestData = this.setRequestData.bind(this)
        this.reset = this.reset.bind(this)
        this.available = this.available.bind(this)
    }

    componentDidMount() {
        fetch(`${appConfig.apiUrl}/v1/2020,2025,2030/both/world?include_tracks=true&geofilter=both`,{
            method: "GET",
            headers: {
                "x-api-key": `${appConfig.apiKey}`
            }
        })
            .then(x => x.json())
            .then(x => {console.log('both', x); both = x; this.setState({hungerData: x}); return x})
            .then(x => this.setState({
                total: calculateTotal(this.state.scarcity, this.state.viewSelected, this.state.view, x, this.state.year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","
                )}))
            .catch(error => {
                console.error('Error fetching both data:', error);
            });

        fetch(`${appConfig.apiUrl}/v1/2020,2025,2030/both/world?include_tracks=true&geofilter=rural`,{
                method: "GET",
                headers:{
                    "x-api-key": `${appConfig.apiKey}`
                }
        })
            .then(x => x.json())
            .then(x => {rural = x; return x})
            .then(x => {
                console.log('rural', x)
                this.setState({
                    rural: calculateTotal(this.state.scarcity, this.state.viewSelected, this.state.view, x, this.state.year)
                })
                }
            )
            .catch(error => {
                console.error('Error fetching rural data:', error);
            });

        fetch(`${appConfig.apiUrl}/v1/2020,2025,2030/both/world?include_tracks=true&geofilter=urban`,{
            method: "GET",
            headers:{
                "x-api-key": `${appConfig.apiKey}`
            }
        })
            .then(x => x.json())
            .then(x => {urban = x; return x})
            .then(x => {
                console.log('urban', x)
                this.setState({
                    urban: calculateTotal(this.state.scarcity, this.state.viewSelected, this.state.view, x, this.state.year)
                })
            })
            .catch(error => {
                console.error('Error fetching rural data:', error);
            });
    }

    reset() {
        let t = calculateTotal('fies', 'All Countries', 'countries', both, '2020')

        this.setState({
            view: 'countries',
            viewSelected: 'All Countries',
            scarcity: 'fies',
            urb: 'both',
            year: '2020',
            live: 'off',
            lense: 'Absolute Hunger',
            total: t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            menuOpen: false
        })
    }

    changeView(newView) {
        let newSelectedView
        switch(newView) {
            case 'continents':
                newSelectedView = "All Continents"
                break
            default:
                newSelectedView = "All Countries"
                break
        }
        let t = calculateTotal(this.state.scarcity, newSelectedView, newView, this.state.hungerData, this.state.year)
        this.setState({
            view: newView,
            total: t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            viewSelected: newSelectedView
        })
    }

    changeViewSelected(newSelected) {
        let t = calculateTotal(this.state.scarcity, newSelected, this.state.view, this.state.hungerData, this.state.year)
        this.setState({
            viewSelected: newSelected,
            total: t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
    }

    changeScarcity(newScarcity) {
        let t = calculateTotal(newScarcity, this.state.viewSelected, this.state.view, this.state.hungerData, this.state.year)
        this.setState({
            scarcity: newScarcity,
            total: t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            urban: calculateTotal(newScarcity, this.state.viewSelected, "All Countries", urban, this.state.year),
            rural: calculateTotal(newScarcity, this.state.viewSelected, "All Countries", rural, this.state.year)

        })
    }

    changeUrb(newUrb) {
        let data = newUrb === "both" ? both : newUrb === "urban" ? urban : rural
        let t = calculateTotal(this.state.scarcity, this.state.viewSelected, this.state.view, data, this.state.year)
        this.setState({
            urb: newUrb,
            hungerData: data,
            total: t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
    }

    changeYear(newYear) {
        let t = calculateTotal(this.state.scarcity, this.state.viewSelected, this.state.view, this.state.hungerData, newYear)
        this.setState({
            year: newYear,
            total: t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            urban: calculateTotal(this.state.scarcity, this.state.viewSelected, "All Countries", urban, newYear),
            rural: calculateTotal(this.state.scarcity, this.state.viewSelected, "All Countries", rural, newYear)
        })
    }

    changeLive(newLive) {
        this.setState({
            live: newLive
        })
    }

    changeLense(newLense) {
        this.setState({
            lense: newLense
        })
    }

    setMenuOpen(newState) {
        this.setState({
            menuOpen: newState
        })
    }

    setRequestData(newState) {
        this.setState({
            requestData: newState
        })
    }

    available() {
        let container = document.querySelector("#container")
        let inner = window.innerHeight
        let scroll = container.scrollTop
        let start = this.state.start
        let footer = true
        if (start) {
            footer = scroll > inner
        }
        if (start) {
            start = !(footer && scroll > inner + 500)
        } else {
            start = scroll < 1
        }
        console.log(start, footer)
        if (start !== this.state.start || footer !== this.state.footer) {
            setTimeout(() => {
                this.setState({
                    start: start,
                    footer: footer
                })
                if (footer && start) {
                    console.log("footer and start now true")
                    container.scrollTop = inner + 6
                }
                if (footer && !start) {
                    container.scrollTop = inner
                }
            }, 1000)

        }
    }

    render() {
        if (this.state.isMaintenanceMode) {
            return <Maintenance />;
        }
        return(
        <Router>
            <Switch>
                <Route path="/methodology">
                    <Methodology></Methodology>
                </Route>
                <Route path="/">
                <GlobalStyles />
                <div id="container" onScroll={this.available}>
                    <div className="child">
                        <Start 
                            data={this.state}
                            visible={this.state.start}
                            calculateTotal={calculateTotal}
                            both={both}
                            urban={urban}
                            setMenuOpen={this.setMenuOpen}
                            rural={rural}/>
                        <Menu data={{
                            menuOpen: this.state.menuOpen
                            }}
                            setRequestData={this.setRequestData}
                            setMenuOpen={this.setMenuOpen}></Menu>
                        <RequestData data={{
                            requestData: this.state.requestData
                            }}
                            setRequestData={this.setRequestData}
                        ></RequestData>
                    </div>
                    <div className="child">
                         <Header 
                            data={
                                this.state
                            }
                            changeView={this.changeView}
                            changeViewSelected={this.changeViewSelected}
                            changeScarcity={this.changeScarcity}
                            changeUrb={this.changeUrb} 
                            setMenuOpen={this.setMenuOpen}
                            div={OptionDiv}
                            menuDiv={MenuDiv} />     
                        {this.state.hungerData != null && rural != null && urban != null && both != null ? <HungerMap
                            data={this.state} 
                            bothData={both}
                            urbanData={urban}
                            ruralData={rural}
                            calculateTotal={calculateTotal}
                            changeViewSelected={this.changeViewSelected}
                            setRequestData={this.setRequestData}
                        /> : <div style={{height: "72vh", display: "flex", alignItems: "center", justifyContent: "center"}}><img src={spinner} /></div>}
                        <BottomMenu 
                            data={{
                                year: this.state.year,
                                live: this.state.live,
                                lense: this.state.lense,
                                scarcity: this.state.scarcity
                            }}
                            changeYear={this.changeYear}
                            changeLive={this.changeLive}
                            changeLense={this.changeLense}
                            div={OptionDiv} />
                        <MobileMenu 
                            data={
                                this.state
                            }
                            changeLive={this.changeLive}
                            changeLense={this.changeLense}
                            changeView={this.changeView}
                            changeViewSelected={this.changeViewSelected}
                            changeScarcity={this.changeScarcity}
                            changeUrb={this.changeUrb} 
                            changeYear={this.changeYear}
                            reset={this.reset}
                            div={OptionDiv}
                            />
                        {both != null && <TopFooter
                            data={
                                this.state
                            }
                            bothData={both}
                            div={OptionDiv} 
                            setRequestData={this.setRequestData}
                            calculateTotal={calculateTotal}/>}
                    </ div>
                    <div className="child">
                        {<Footer
                            data={
                                this.state
                            }
                            visible={this.state.footer}
                            bothData={both}
                            div={OptionDiv} 
                            setRequestData={this.setRequestData}
                            calculateTotal={calculateTotal}/>}
                    </ div>
                </ div>
                </Route>
            </ Switch>
        </Router>
        )
    }
}

export default App