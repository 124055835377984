import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo} from './assets/img/logoNoText.svg'

/**
 * This is the methodology page
 */


const Layout = styled.div`
    display: grid;
    grid-template-rows: 8vh 1fr;
    grid-template-columns: 1fr;
`

const Header = styled.div`
    width: 80%;
    justify-self: center;
    display: grid;
    grid-template-columns: 75px 225px 1fr;
    grid-area: 1 / 1 / 2 / 2;
    align-items: center;
    @media (max-width: 768px) {
        padding: 0;
        display: flex;
        justify-content: center;
    }
`

const WhiteLink = styled.a`
    max-width: 100%;
    color: black;
    font-size: 1.3em;
    font-weight: 200;
    text-decoration: none;
    margin-right: 25px;
    font-family: Work Sans;
`

const StyledLink = styled(Link)`
    justify-self: end;
    text-decoration: none;
    font-family: Work Sans;
    color: black;
    font-size: 1.5em;
`

function Methodology() {

    return (
        <Layout>
            <Header>
                <a href="https://worlddata.io"
                ><Logo style={{width: "75px", height: "75px"}}></Logo></a> 
                <WhiteLink href="https://worlddata.io">WORLD DATA LAB</WhiteLink>
                <StyledLink style={{justifySelf: "end", textDecoration:"none", fontFamily: "Work Sans"}} to="/">
                    &lt; Back to the Hunger Clock
                </StyledLink>
            </Header>
            <div style={{justifySelf:"center", width: "80%", gridColumnStart:"1", gridColumnEnd:"3",fontFamily:"Work Sans"}}>
                <h1>Methodology</h1>
                <p>The World Hunger Clock shows two different aspects of hunger, the novel experience-based metric of food insecurity and the metric of stunting. The later can be seen as a consequence of chronic hunger. The World Data Lab modeled food insecurity on a subnational level for the whole world. The estimates on stunting originate from a research group called &quot;Local Burden of Disease Child Growth Failure Collaborators&quot; which published their high resolution estimates in the following paper: <a href="https://www.nature.com/articles/s41586-019-1878-8">Mapping child growth failure across low- and middle-income countries</a>. In addition to the metrics on food insecurity and stunting, acute phases of hunger are displayed in the Live Mode. This data is retrieved from the <a href="https://fews.net/">Famine Early Warning Systems Network</a> and is based on their definition of crisis, emergency and famine.</p>
                <h2 id="food-insecurity">Food Insecurity</h2>
                <p>The methodology for estimating food insecurity rates at the subnational level involved three steps:</p>
                <ol>
                <li>Use FAO survey microdata to estimate subnational levels of food insecurity for all countries with individual-level data.</li>
                <li>Combine and harmonize data on variables related to development, the environment, health, and market access as covariates for all subnational areas from 2010 to 2030.</li>
                <li>Use a random forest model to estimate global rates of food insecurity, subnationally, from the years 2010 to 2030.</li>
                </ol>
                <h2 id="disaggregating-individual-level-fies-microdata">Disaggregating Individual-Level FIES Microdata</h2>
                <p>We started with 331 surveys from 77 countries from the years 2014 to 2018 from the <a href="https://microdata.fao.org/index.php/catalog/Food-Security">FAO microdata catalogue</a>. This survey data comes in the form of individual-level data, with approximately 1,000 individuals interviewed per survey.  For each individual, data on their age, gender, education, locality (urban/rural) and national wealth quintile is collected, as well as whether they exhibited 8 behaviors indicative of food insecurity over the previous year.  Based on these 8 behaviors, using <a href="https://doi.org/10.1016/j.measurement.2017.10.065">a Rasch model method</a>, the FAO estimates the probability that an individual is over the threshold for severe and moderate-to-severe food insecurity according to the Food Insecurity Experience Scale (FIES). To get estimates of national prevalences, the FAO assigns each individual a weight based on how their demographic characteristics are more or less representative of national characteristics compared to the sample of individuals interviewed, and then takes the weighted mean probability of being over thresholds for severe and moderate-to-severe food insecurity.</p>
                <p>We use a similar methodology to the FAO, except we use subnational data on wealth, gender, age, and urbanization to calculate separate weights at each subnational level.  We use each individual in the country sample for each subnational calculation with different weights each time.  To estimate subnational prevalences of age and gender, we use data from <a href="https://worldpop.org">WorldPop</a>, for subnational prevalences of urban and rural population, we use data from <a href="https://doi.org/10.1088/1748-9326/11/8/084003">Jones &amp; O&#39;Neill (2016) <em>Envir. Res. Lett.</em></a>, and for the subnational prevalences of wealth quintiles, we use data from <a href="https://dhsprogram.com/">Demographic and Health Surveys</a>, where available.  This methodology allows us to estimate and model the FIES at a subnational level, and rests on the mild assumption that individuals of the same age, gender, urban/rural context and national wealth quintile will have the same risk of food insecurity, no matter where in the country they are observed.</p>
                <h2 id="covariates">Covariates</h2>
                <p>We use a dozen covariates characterizing food systems to model food insecurity rates over space and over time.  For each covariate that is not time-invariant, we aim for data sources that have been peer-reviewed and include projections to at least the year 2030.  For projections that come from scenario or simulation based methodologies, we use the most middle-of-the-road estimates.  Thus, for climate projections, we use estimates from Representative Concentration Pathway (RCP) 6.5, and for projections from the Shared Socio-Economic Pathways (SSP) framework, we use projections from SSP 2. The following variables, with their respective data sources, are:</p>
                <ul>
                <li><strong>Urban Percentage</strong> - <a href="https://doi.org/10.1088/1748-9326/11/8/084003">Jones &amp; O&#39;Neill (2016) <em>Environmental Research Letters</em></a> </li>
                <li><strong>Stunting</strong> - <a href="https://doi.org/10.1038/s41586-019-1878-8">Local Burden of Disease (2020) <em>Nature</em></a> </li>
                <li><strong>Wasting</strong> - <a href="https://doi.org/10.1038/s41586-019-1878-8">Local Burden of Disease (2020) <em>Nature</em></a> </li>
                <li><strong>Mean Years of Schooling</strong> - <a href="https://doi.org/10.1038/sdata.2019.38">Smits and Permanyer (2019) <em>Scientific Data</em></a>, <a href="https://doi.org/10.1016/j.gloenvcha.2014.06.004">KC (2017) <em>Global Environmental Change</em></a> </li>
                <li><strong>GDP Per Capita</strong> - <a href="https://doi.org/10.1038/sdata.2019.38">Smits and Permanyer (2019) <em>Scientific Data</em></a>, <a href="https://doi.org/10.1016/j.gloenvcha.2015.06.004">Dellink et al. (2017), <em>Global Environmental Change</em></a></li>
                <li><strong>Gini Coefficient</strong> - <a href="https://doi.org/10.1016/j.futures.2018.07.001">Rao et al. (2019) <em>Futures</em></a> </li>
                <li><strong>Poverty Headcount Index</strong> - <a href="https://doi.org/10.1057/s41599-018-0083-y">Cuaresma et al. (2018) <em>Palgrave Communications</em></a></li>
                <li><strong>Water Scarcity</strong> - <a href="https://doi.org/10.1038/s41893-018-0134-9">Greve et al. (2018) <em>Nature Sustainability</em></a></li>
                <li><strong>Topographic Ruggedness</strong> - <a href="https://doi.org/10.5066/F7DF6PQS">USGS (1996)</a></li>
                <li><strong>Mean Temperature</strong> - <a href="https://doi.org/10.1038/sdata.2017.191">Abatzoglou et al. (2018) <em>Scientific Data</em></a>, <a href="https://doi.org/10.1073/pnas.1312330110">Warszawski et al. (2014) <em>PNAS</em></a></li>
                <li><strong>Mean Annual Precipitation</strong> -  <a href="https://doi.org/10.1038/sdata.2017.191">Abatzoglou et al. (2018) <em>Scientific Data</em></a>, <a href="https://doi.org/10.1073/pnas.1312330110">Warszawski et al. (2014) <em>PNAS</em></a></li>
                <li><strong>Malaria <em>P. falciparum</em> Mortality Rate</strong> -  <a href="https://doi.org/10.1016/S0140-6736(19">Weiss et al. (2019) <em>The Lancet</em></a> (31097-9)</li>
                </ul>
                <h3 id="combining-harmonizing-and-extrapolating">Combining, Harmonizing and Extrapolating</h3>
                <p>For urban percentage, Gini coefficient, poverty headcount index, and water scarcity the dataset covered all of the years of the study.  </p>
                <p>For stunting, wasting, and malaria mortality rate, we estimate the Annualized Rate of Change (AROC) for each subnational area.  The involves taking the rate of change between each pair of years in the dataset, and then taking the mean rate of change over the period for which data is available, giving greater weight to more recent years.  We then estimate future changes in stunting, wasting, and malaria mortality, based on this rate of change.</p>
                <p>For mean years of schooling, GDP per capita, as well as for projections of population, we had subnational historic data and future projections at the national level.  We used observed trajectories in the historical inequalities among subnational areas within a country to estimate the future distribution of GDP, population, and schooling among subnational areas and disaggregate national-level future projections.</p>
                <p>For temperature and precipitation, we combined historical observations with an ensemble of bias-corrected projections for the future climate from the Combined Model Intercomparison Project 5 (CMIP5).</p>
                <h3 id="accounting-for-covid-19">Accounting for COVID-19</h3>
                <p>Most of these datasets were created before the COVID-19 pandemic, and therefore their projections need to be adjusted in light of the impacts of the pandemic on the global economy and food systems.  The estimates of poverty headcounts and GDP per capita were revised based on World Bank estimates of the impact of the pandemic on country-level GDP growth for the years 2020 and 2021, with economic growth resuming its previously estimated rate of change after that time.  Additionally, <a href="https://doi.org/10.1016/S0140-6736(20"><em>The Lancet</em></a> (31647-0) has published estimates of the impact of the pandemic on child anthropometry, which we used to adjust estimates of stunting and wasting for 2020 and 2021, with those two variables returning to their projected trajectories by 2022.</p>
                <h3 id="missing-data">Missing Data</h3>
                <p>In cases where countries were missing data on covariates, we used estimates from the most similar plausible country.  By estimating variables for countries with missing data, we are able to include every county in the model, make estimates of global totals of the food insecure population.</p>
                <ul>
                <li>For Syria, we used the same poverty headcount rates as Yemen, another conflict-affected middle eastern nation.</li>
                <li>For South Sudan, we used the same projections of GDP per capita and Gini coefficient as the Central African Republic, another landlocked and under-developed central African nation.</li>
                <li>For North Korea, we estimated the same level of education and GDP per capita as Tajikistan, another economically isolated, authoritarian Asian nation.</li>
                </ul>
                <h2 id="modeling-food-insecurity-over-time-and-space">Modeling Food Insecurity Over Time and Space</h2>
                <p>We trained random forest models on the subnational estimates of food insecurity prevalence, with separate models for severe and moderate-to-severe food insecurity.  We estimated model hyperparameters, with cross-validation.  Overall, or model performed very well.  With 10-fold cross validation, the model has an r^2^ of about 0.98, and on the full dataset, it has an r^2^ of greater than 0.99.  Finally, we tested the model on national estimates reported by the FAO.  These estimates do not include individual level-data and in many cases include countries that were not included in model fitting at all.  On this out-of-sample dataset, the model had an r^2^ of about 0.96.  </p>
                <h2 id="data-code-and-future-publications-">Data and Future Publications.</h2>
                <p>Any questions about the data can be asked by contacting us at <a href="https://worlddata.io/contact">World Data Lab</a>.</p>
                <p>This analysis is currently under peer review and this page will be updated when it is published.</p>
            </div>
        </Layout>
    )

}

export default Methodology