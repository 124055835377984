import React from 'react'
import styled from 'styled-components'

 /**
  * This is the center div for this burger menu
  */
const StyledBurger = styled.button`
    grid-column-start: 2;
    grid-column-end: 3; 
    grid-row-start: 1; 
    grid-row-end: 3; 
    justify-self: start;
    align-self:center;
    display: flex;
    background: inherit;
    flex-direction: column;
    justify-content: space-around;
    width: 26px;
    height: 22px;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    div {
        width: 26px;
        height: 2px;
        background: #c76306;
        border-radius: 10px;
        position: relative;

    }
`

function Burger(props) {

    return (
        <StyledBurger  open={props.menuOpen} onClick={() => props.setMenuOpen(true)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}

export default Burger