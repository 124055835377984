import React from 'react'
import styled from 'styled-components'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const Background = styled.div`
    width: ${x => x.open ? "100%" : "0%"};
    position: fixed;
    height: 100%;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
`

const Card = styled.div`
    width: 34%;
    height: 80%;
    position: absolute;
    top: 5%;
    left: 31%;
    background: white;
    display: ${x => x.open ? "grid" : "none"};
    grid-template-rows: 2% 12% 12% 40% 1fr;
    justify-content: center;
    overflow: visible;
    padding-bottom: 50px;
    @media (max-width: 1680px) {
        width: 40%;
        left: 30%;
    }
    @media (max-width: 1350px) {
        width: 50%;
        left: 25%;
    }
    @media (max-width: 1050px) {
        width: 80%;
        left: 10%;
        overflow: scroll;
    }
    @media (max-width: 600px) {
        height: 98%;
        width: 94%;
        left: 3%;
        top: 1%;
    }
`

const InputFields = styled.div`
    display: grid;
    width: 80%;
    height: 100%;
    justify-self: center;
    grid-template-rows: 20% 20% 20% 1fr;
`

const X = styled.p`
    justify-self: end;
    font-size: 2em;
    margin: 0;
    cursor: pointer;
    padding: 5px;
    margin-right: 20px;
`

const ThankYou = styled.h1`
    font-family: Berlingske Serif;
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    @media (max-width: 1500px) {
        font-size: 2em;
    }
`

const Explanation = styled.p`
    font-size: 0.9em;
    margin: 1em 1.5em;
    text-align: center;
    justify-self: center;
    width: 80%;
`

const Input = styled.input`
    border: none;
    border-bottom: 1px solid black;
    justify-self: center;
    width: 100%;
`

const SubmitDiv = styled.div`
    display: grid;
    grid-template-rows: 50% 50%;
    align-items: center;
    justify-content: center;
`

const Submit = styled.button`
    background-color: rgb(238, 67, 34);
    color: white;
    border: none;
    font-size: 1.3em;
    font-weight: bold;
    padding: 10px 30px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 10px 0px;
    border-radius: 5px;
    margin-bottom: 15px;
    grid-area: 2 / 1 / 3 / 2;
`

const FinePrint = styled.p`
    font-size: .8em;
    line-height: 20px;
    width: 80%;
    justify-self: center;
    @media (max-width: 1450px) {
        font-size: 0.75em;
        line-height: 20px;;
    }
    @media (max-width: 700px) {
        line-height: 15px;
    }
`

/**
 * Returns the input fields and subscribe buttom
 * @param {status, message, and onValidated methods} param0 
 */
const CustomForm = ({ status, message, onValidated }) => {

    let email, firstName, lastName
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      firstName &&
      lastName &&
      onValidated({
        EMAIL: email.value,
        FIRSTNAME: firstName.value,
        LASTNAME: lastName.value
      });

      return (
          <InputFields>
                <Input ref={node => (email = node)} type="email" placeholder="EMAIL*"  style={{borderTop: "1px solid black"}}></Input>
                <Input ref={node => (firstName = node)} type="text" placeholder="FIRST NAME*"></Input>
                <Input ref={node => (lastName = node)} type="text" placeholder="LAST NAME*"></Input>
                <SubmitDiv>
                {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
                {status === "error" && (
                    <div
                    dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                    dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                    <Submit onClick={submit}>Subscribe</Submit>
                </SubmitDiv>
          </InputFields>
      )
}

function RequestData(props) {

    return (
        <Background open={props.data.requestData}>
            <Card open ={props.data.requestData}>
                <X onClick={() => props.setRequestData(false)}>X</X>
                <ThankYou>Thank you for your interest</ThankYou>
                <Explanation>We are working on expanding features of the Hunger Clock. Please leave your email to get notified when this is ready</Explanation>
                <MailchimpSubscribe 
                    url={'https://worlddata.us13.list-manage.com/subscribe/post?u=3840f1496862a7a719676d23a&id=d7c17dc9ef'}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                      />
                    )}
                />
                <FinePrint>World Data Lab (WDL) is an Austrian not-for-profit organization responsible for building and maintaining the World Hunger Clock amongst other similar projects.  If you fill out the form below your information will be used to 1) send you updates about improvements to the clock as they become available 2) send you articles about the Hunger Clock, as well as other similar projects that WDL is working on 3) create an internal database of users interested in learning how to access World Hunger Clock datasets and related products.  At no time will WDL sell or share your data to any non-related entity.  You have the right to remove your data from our database by clicking on the "unsubscribe" link at the bottom of any email you receive in the future or to write to World Data Lab requesting to be unsubscribed from our database at any time at the following email address:: wpc@worlddata.io. </FinePrint>
            </Card>
        </Background>
    )
}

export default RequestData