import React, { useState } from 'react'
import styled from 'styled-components'
import { MobileDropDownDiv, Current, DropDown, DropDownItem } from './../assets/css/commonStyles.js'

/**
 * This is the drop down menu used for filtering on mobile devices
 */

const DropDownDiv = styled.div`${DropDown}`

const DropDownElement = styled.p`${DropDownItem}`

function MobileDropDown(props) {

    const [open, setOpen] = useState(false);

    return (
        <MobileDropDownDiv>
            <Current onClick={() => setOpen(!open)} >{props.selected}</Current>

            {open && 
                <DropDownDiv top={props.top + "vh"} style={{width: "80%"}}>
                    {props.data.map(x => <DropDownElement 
                    key={x}
                    weight={props.selected === x ? 600 : 400}
                    onClick={() => {
                        setOpen(false)
                        props.onClick(x)}}
                    >{x}</DropDownElement>)}
                </DropDownDiv>}
        </MobileDropDownDiv>
    )
}

export default MobileDropDown